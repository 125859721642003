import React from "react";
// Customizable Area Start
import {
    Box,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    Button,
    TextField, 
    MenuItem,
    Popover,
    Input,
    CircularProgress,
    Grid,
    IconButton,
} from "@material-ui/core";
import ArrowIcon from '@material-ui/icons/ArrowBack';
import Pagination from '@mui/material/Pagination';
import { styled } from "@material-ui/styles";
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CustomFormController, {
    Props,
    configJSON,

} from "./CustomFormController.web";
import Headers from '../../../components/src/Headers.web';
import EmployeHeader from '../../../components/src/EmployeHeader.web'
import KycWbSDK from '../../../components/src/KycWbSDK.web'
import Skeleton from '@material-ui/lab/Skeleton';
import DownloadPdfCustom from "../../../components/src/DownloadPdf"
const profileImg2 = require("../assets/blankProfile.jpeg");
// Customizable Area End

export default class CustomForm extends CustomFormController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
    getStatusButton = (status: string) => {
        const { anchorEl } = this.state;
        const open = Boolean(anchorEl);
        const rejectText = this.state.rejectedText;
        switch (status) {
            case 'GREEN':
                return (
                    <Button className="approvedBtn">
                        {configJSON.approvedText}
                    </Button>
                );
            case 'Pending':
                return (
                    <Button className="pendingBtn">
                        {configJSON.pendingText}
                    </Button>
                );
            case 'RED':
                return (
                    <>
                        <Button
                            className={rejectText ? "rejectedBtn" : "needClarificationBtn"}
                            data-testId="rejectTestId"
                            onClick={this.handleClick}
                            endIcon={<ExpandMoreIcon />}
                        >
                            {rejectText ? configJSON.rejectedText : configJSON.clearificationText}
                        </Button>
                        <Popover
                            open={open}
                            anchorEl={anchorEl}
                            data-testId="closeAnchorTestId"
                            onClose={this.handleClose}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                            }}
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                            PaperProps={{
                                style: {
                                    marginTop: "10px",
                                    backgroundColor: !this.state.rejectedText ? "red" : "#FF7A01",
                                    color: "black",

                                },
                            }}
                        >
                            <MenuItem className="needClaritiy" data-testId = "needClarificationTestId" onClick={this.handleNeedClarification}> {!rejectText ? configJSON.rejectedText : configJSON.clearificationText}
                            </MenuItem>
                        </Popover>
                    </>
                );
            default:
                return "No Status";
        }
    };
    getStatusCode = (status: string)=> {        
        switch (status) {
            case 'GREEN':
                return (
                    <Typography className="companyInfoTitle3" style={{color:"#41d613"}} > APPROVED </Typography>
                );
            case 'Pending':
                return (
                    <Typography className="companyInfoTitle3" style={{color:"yellow"}}> PENDING </Typography>
                );
            case 'RED':
                return (
                   <Typography className="companyInfoTitle3" style={{color:"red"}}> REJECTED </Typography>
                );
            default:
                return "No Status";
        }
    };

    renderSkeletonLoader=()=>{

        const Arr=[1,2,3,4,5,6,7,8];
        return(
          <TableBody >
      
            {
      
            Arr.map(i=>(
            <TableRow key={i}>
      {
        Arr.map(j=>(
          <TableCell key={j}>
          <Skeleton style={{background: ' rgb(113, 108, 108)', borderRadius: '6px'}}  width={200} variant="text" />
          </TableCell>
        ))
      }
            </TableRow>
            ))
      }
      
          </TableBody>
        )
      }


      renderNoDataFoundEmployee=()=>{



        return(
        <TableBody>
<TableCell colSpan={8}>
              <div style={{display:'flex',alignItems:'center',flexDirection:'column',justifyContent:'center',padding:"50px 0px",
              background: '#242424'}}>


              <img src={require("../assets/image_.svg")} style={{width:'25%'}}  />
  <p style={{color:'white',fontWeight:'bold', fontSize:'20px'}}>No Data Found</p>
    
         </div> 
         </TableCell>
         </TableBody>
        )

        
      }


      handleStatusBlockCustomForm=()=>{
        return(
        <div 
        style={{position:'relative'}} className="statusDiv"
        >
    
                <Box style={{padding: '10px',position:'absolute', background:'#505050',width:'100%',boxSizing:'border-box'}} >
                <Button onClick={()=>this.handleStatusClick('green')}  className={this.state.highlightStatus && this.state.status === 'green' ? 'highlighted1vk' : 'nothighlighted1vk'} data-testId="statusApproved" >Approved</Button>
                 <Button   className={this.state.highlightStatus && this.state.status === 'pending' ? 'highlighted2vk' : 'nothighlighted2vk'} onClick={()=>this.handleStatusClick('pending')}  data-testId="statuspending" >Pending</Button>
                 <Button data-testId="statusrejected"  className={this.state.highlightStatus && this.state.status === 'red' ? 'highlighted3vk' : 'nothighlighted3vk'}  onClick={()=>this.handleStatusClick('red')}>Rejected</Button>
                 <Button  onClick={()=>this.handleStatusClick('need clarification')}  className={this.state.highlightStatus && this.state.status === 'need clarification' ? 'highlighted4vk' : 'nothighlighted4vk'} data-testId="statusneedclarification" >Need clarification</Button>
                        </Box>
                        <style>
{`
.highlighted1vk {
    margin-right: 5px;
    margin-bottom: 5px;
  border: 1px solid rgba(0, 255, 0, 1);
  text-transform: none; 
    cursor: pointer;
  background: rgba(0, 255, 0, 1);
  color: white;
 
}

.highlighted1vk:hover {
  background: rgba(0, 255, 0, 1);
  margin-bottom: 5px;
  color: white;
  text-transform: none; 
  cursor: pointer;
  margin-right: 5px;
}

.nothighlighted1vk {
  margin-right: 5px;
  text-transform: none; 
  cursor: pointer;
  border: 1px solid rgba(0, 255, 0, 1);
  color: rgba(0, 255, 0, 1);
  margin-bottom: 5px;
 
}

.highlighted2vk {
  text-transform: none; 
  cursor: pointer;
  color: white;
  margin-bottom: 5px;
  margin-right: 5px;
  border: 1px solid rgba(251, 212, 0, 1);
  background: rgba(251, 212, 0, 1);
 
}

.highlighted2vk:hover {
  background: rgba(251, 212, 0, 1);
  margin-bottom: 5px;
  text-transform: none;
  color: white;
  margin-right: 5px; 
  cursor: pointer;
}

.nothighlighted2vk {
    margin-bottom: 5px;
    text-transform: none; 
    border: 1px solid rgba(251, 212, 0, 1);
  cursor: pointer;
  color: rgba(251, 212, 0, 1);
  margin-right: 5px;
 
}

.highlighted3vk {
    margin-right: 5px;
    margin-bottom: 5px;
    border: 1px solid rgba(255, 25, 56, 1);
  background: rgba(255, 25, 56, 1);
  color: white;
  
  cursor: pointer;
  text-transform: none; 
}

.highlighted3vk:hover {
    color: white;
    margin-right: 5px;
    text-transform: none;
    cursor: pointer;
    margin-bottom: 5px;
    background: rgba(255, 25, 56, 1);
}

.nothighlighted3vk {
    color: rgba(255, 25, 56, 1);
    margin-right: 5px;
    margin-bottom: 5px;
    cursor: pointer;
    border: 1px solid rgba(255, 25, 56, 1);
  text-transform: none; 
}

.highlighted4vk {
  border: 1px solid rgba(255, 122, 1, 1);
  background: rgba(255, 122, 1, 1);
  color: white;
  margin-right: 5px;
  margin-bottom: 5px;
  text-transform: none; 
  cursor: pointer;
}

.highlighted4vk:hover {
  background: rgba(255, 122, 1, 1);
  color: white;
  margin-right: 5px;
  margin-bottom: 5px;
  text-transform: none; 
  cursor: pointer;
}

.nothighlighted4vk {
    color: rgba(255, 122, 1, 1);
    border: 1px solid rgba(255, 122, 1, 1);
  margin-right: 5px;
  cursor: pointer;
  margin-bottom: 5px;
  text-transform: none; 
}
`}
</style>

                        </div>
        )

      }


      handleRenderContent = () => {
        const { userDataLoading, noDataFound } = this.state;
      
        if (userDataLoading) {
          return this.renderSkeletonLoader();
        }
      
        if (noDataFound) {
          return this.renderNoDataFoundEmployee();
        }
      
        return this.renderPaginationCell();
      }


      renderPaginationCell = () => {
        return (
            <TableBody>

                {
                    this.state.userData?.length > 0
                        ? this.state.userData.map((singleData) => {
                            return (
                                <TableRow key={singleData?.id}>
                                    <TableCell>
                                        <UserName>
                                            <img 
                                                src={singleData.attributes?.profile_picture || profileImg2 }
                                                alt="no image" 
                                                className="userImg" 
                                            />
                                            <Typography className="nameTxt" onClick={()=>this.getDetailsofUser(singleData?.attributes?.applicant_id)}  data-testId="backbuttontestID1" >                                         
                                              {
                                                singleData.attributes.firstName ? singleData.attributes.firstName.length > 8
                                                    ? singleData.attributes.firstName.charAt(0).toUpperCase() + singleData.attributes.firstName.slice(1, 8).toLowerCase() + "..."
                                                    : singleData.attributes.firstName.charAt(0).toUpperCase() + singleData.attributes.firstName.slice(1).toLowerCase()
                                                    : ""
                                              }
                                            </Typography>                                          
                                        </UserName>
                                    </TableCell>
                                    <TableCell className="dobTableTxt">
                                        {singleData?.attributes?.dob}
                                    </TableCell>
                                    <TableCell className="nationalityText">
                                        {singleData?.attributes?.nationality}
                                    </TableCell>
                                    <TableCell className="countryTableText">
                                        {singleData?.attributes?.country}
                                    </TableCell>
                                    <TableCell className="addressText">
                                        {singleData?.attributes?.addresses}
                                    </TableCell>
                                  
                                    <TableCell className="passportTextTable">
                                    {singleData.attributes?.passport_number  ? (singleData.attributes.passport_number.length > 8 ? `${singleData.attributes.passport_number.slice(0, 8)}...` : singleData.attributes.passport_number): ''}
                                     </TableCell>
                                 
                                    <TableCell>
                                        {
                                            [`${singleData?.attributes?.passport}`].map((document: string, index: number) => (
                                                <TableDocWrapper key={document}>
                                                    <Box className="singleDoc">
                                                  
                                                     <DownloadPdfCustom downloadIcon={require("../assets/image_.png")} document={document} setDownloadPdfLoader={this.setDownloadPdfLoader1} />

                                                        <img src={require("../assets/image_texts.png")} />
                                                        <Typography className="documentNameText">
                                                            passport.pdf
                                                        </Typography>
                                                    </Box>
                                                </TableDocWrapper>
                                            ))
                                        }
                                    </TableCell>
                                    <TableCell>
                                        <StatusWrapper style={{ width: "130px" }}>                                                           
                                                 {this.getStatusButton(singleData.attributes.status.review_result?.reviewAnswer)}
                                        </StatusWrapper>
                                    </TableCell>
                                </TableRow>
                            )
                        })
                        :
                        <TableRow>
                            <TableCell colSpan={8} style={customStyles.noDataFoundDiv}>
                                <Box style={customStyles.noDataFoundBox}>
                                    <Typography style={customStyles.noDataFoundText}>
                                        No Data Found
                                    </Typography>
                                </Box>
                            </TableCell>
                        </TableRow>
                }
            </TableBody>
        );
    };
    

    renderEmployeForm = () => {
        return (
            <div style={{height:"100%"}}>
            <MainContent style={{overflowY:"auto", height: "calc(100% - 71px)",boxSizing:"border-box"}}>
               
                <PageTop className="pageMain">
                    <Typography className="headerText" >{configJSON.userInformation}</Typography>

                    <Box className="searchContainer">
  
                        <Box className="searchInputContainer">
                            <Input
                                className="searchInputfield"
                                fullWidth
                                placeholder="Search"
                                disableUnderline
                                name="cardNumber"
                                data-testId="cardNumberTestIdSearch"
                                onChange={this.handleChangeSearchFun}
                                onClick={this.handleToggleStatus}
                                value={this.state.searchKey}
                                startAdornment={
                                    <SearchIcon className="searchIcon" />
                                }
                                endAdornment={
                                    <ClearIcon  onClick={this.clearData} className="crossIcon" data-testId="crossicon" />
                                }
                            />

                            

{
                    this.state.showStatusDiv &&
               
              this.handleStatusBlockCustomForm()
                          
        
}


                        </Box>

                        <Button data-test-id="navigationToKyc" className="searchBtn" variant="contained" onClick={this.kycNavigation}>{configJSON.verifyText}</Button>
                      {false && <Button  data-testId="navigationToKyc1" className="searchBtn" variant="contained" onClick={this.CheckSDK}>Verify Passport</Button>}
                    </Box>     

                  


                </PageTop>
               
                <StyledTableContainer style={{ overflowX: this.state.userDataLoading ? 'hidden' : 'auto'}} className="StyledTableContainer">
                    <StyledTable>
                        <TableHead>
                            <TableRow>
                                {

                                    this.state.tableHeaderData.filter((hData: { [key: string]: string }) => {
                                        if (this.state.searchValue) {
                                            return hData.name === "Name" || hData.name === "Date of Birth" || hData.name === "Nationality" || hData.name === "Status"
                                        }
                                        return hData.name.includes("");
                                    }).map((hData: { [key: string]: string }) => <TableCell className={hData.className} key={hData.name}>{hData.name}</TableCell>)
                                }
                            </TableRow>
                        </TableHead>                   
                        {
                        
                           this.handleRenderContent()
                        }
                    </StyledTable>
                </StyledTableContainer>
                {
                    !this.state.userDataLoading &&
                    <PagginationBox data-testId="paginationBox">
                    <Pagination data-testId="paginationTestId" count={Math.ceil(this.state.customUserListLength  / this.state.customperPage) || 0} variant="outlined" shape="rounded" onChange={this.handlePageChange} page={this.state.customCurrentPage} />
                </PagginationBox>

              }     
             
            </MainContent>
            </div>
        )
    }

    renderPerticulerDetails2 = () => {
        return (
          <div style={{ height: "100%" }}>
            <MainContent
              style={{
                overflowY: "auto",
                height: "calc(100% - 71px)",
                boxSizing: "border-box",
              }}
            >
              <div style={{ display: "flex" }}>
                <IconButton
                  onClick={this.checkforviewFun}
                  data-testId="backbuttontestID"
                  className="downloadLargeBtn"
                >
                  <ArrowIcon className="backBtn" />
                </IconButton>
      
                <Typography className="companyInfoTitle">
                  Verification Details
                </Typography>
              </div>
      
              <div
                style={{
                  padding: "46px",
                  backgroundColor: "#18191b",
                }}
              >
          
                <div className="CardBoxParent">
                  <Typography
                    className="companyInfoTitle2"
                    style={{
                      fontWeight: "bold",
                      fontSize: "26px",
                      marginBottom: "8px",
                    }}
                  >
                    Overview
                  </Typography>
      
                  <div className="CardBox">
                    <div className="CardBoxWith">
                      <Typography
                        className="companyInfoTitle2"
                        style={{ fontWeight: "bold" }}
                      >
                        Name:
                      </Typography>
      
                      <Typography className="companyInfoTitle3">
                        {" "}
                        {this.state.userInspectionData?.info?.firstName}
                      </Typography>
                    </div>
      
                    <div className="CardBoxWith">
                      <Typography
                        className="companyInfoTitle2"
                        style={{ fontWeight: "bold" }}
                      >
                        Inspection report:
                      </Typography>
      
                      <Typography className="companyInfoTitle3">
                        {" "}           
                        {this.state.userInspectionData?.createdAt?.split(" ")[0]}
                      </Typography>
                    </div>
      
                    <div className="CardBoxWith">
                      <Typography
                        className="companyInfoTitle2"
                        style={{ fontWeight: "bold"}}
                      >
                        Creadted for:
                      </Typography>
      
                      <Typography
                        className="companyInfoTitle3"
                      >
                        {" "}
                        {this.state.userInspectionData?.clientId}
                      </Typography>
                    </div>
      
                    <div className="CardBoxWith">
                      <Typography
                        className="companyInfoTitle2"
                        style={{ fontWeight: "bold"}}
                      >
                        Verification Status:
                      </Typography>
                      <Typography
                        className="companyInfoTitle3"            
                      >
                        {this.getStatusCode(this.state.userInspectionData?.review?.reviewResult.reviewAnswer  ?? "No Status") }
                      </Typography>
                    </div>

                  </div>
                </div>
       
                <div className="CardBoxParent">
                  <Typography
                    className="companyInfoTitle2"
                    style={{
                      fontWeight: "bold",
                      fontSize: "26px",
                      marginBottom: "8px",
                    }}
                  >
                    Profile Data
                  </Typography>
      
                  <div className="CardBox">
                    <div className="CardBoxWith">
                      <Typography className="companyInfoTitle4">ID: </Typography>
                      <Typography className="companyInfoTitle3" style={{fontSize:"19px",wordWrap:"break-word"}}>  
                        {this.state.userInspectionData?.id}
                      </Typography>
                    </div>
      
                    <div className="CardBoxWith">
                      <Typography className="companyInfoTitle4">
                        External User ID:{" "}
                      </Typography>
                      <Typography className="companyInfoTitle3" style={{fontSize:"19px",wordWrap:"break-word"}}>
                        {" "}
                        {this.state.userInspectionData?.externalUserId}
                      </Typography>
                    </div>
      
                    <div className="CardBoxWith">
                      <Typography className="companyInfoTitle4">
                        Created for:{" "}
                      </Typography>
                      <Typography className="companyInfoTitle3">
                        {" "}
                        {this.state.userInspectionData?.createdAt?.split(" ")[0]}
                      </Typography>
                    </div>
      
                    <div className="CardBoxWith">
                      <Typography className="companyInfoTitle4">
                        Profile created:{" "}
                      </Typography>
                      <Typography className="companyInfoTitle3">
                        {" "}
                        {this.state.userInspectionData?.clientId}
                      </Typography>
                    </div>
      
                    <div className="CardBoxWith">
                      <Typography className="companyInfoTitle4">
                        Requested document:{" "}
                      </Typography>
                      {this.state.userInspectionData?.requiredIdDocs?.docSets[0].types.map(
                        (data, index) => {
                          return (
                            <Typography className="companyInfoTitle3">
                              {" "}
                              {data}
                            </Typography>
                          );
                        }
                      )}
                    </div>
                  </div>
                </div>
      
       
                <div className="CardBoxParent">
                  <Typography
                    className="companyInfoTitle2"
                    style={{
                      fontWeight: "bold",
                      fontSize: "26px",
                      marginBottom: "8px",
                    }}
                  >
                    Provided Data
                  </Typography>
      
                  <div className="CardBox">
                    <div className="CardBoxWith">
                      <Typography className="companyInfoTitle4">
                        First Name:{" "}
                      </Typography>
                      <Typography className="companyInfoTitle3">
                        {this.state.userInspectionData?.fixedInfo?.firstName}
                      </Typography>
                    </div>
      
                    <div className="CardBoxWith">
                      <Typography className="companyInfoTitle4">
                        Full Name Transliteration:{" "}
                      </Typography>
                      <Typography className="companyInfoTitle3">
                        { this.state.userInspectionData?.fixedInfo?.firstNameEn}
                      </Typography>
                    </div>
      
                    <div className="CardBoxWith">
                      <Typography className="companyInfoTitle4">
                        Date of Birth:{" "}
                      </Typography>
                      <Typography className="companyInfoTitle3">
                        {this.state.userInspectionData?.fixedInfo?.dob}
                      </Typography>
                    </div>
      
                    <div className="CardBoxWith">
                      <Typography className="companyInfoTitle4">Country: </Typography>
                      <Typography className="companyInfoTitle3">
                        {this.state.userInspectionData?.fixedInfo?.country}
                      </Typography>
                    </div>
                  </div>
                </div>
      
  
                <div className="CardBoxParent">
                  <Typography
                    className="companyInfoTitle2"
                    style={{
                      fontWeight: "bold",
                      fontSize: "26px",
                      marginBottom: "8px",
                    }}
                  >
                    Personal Data
                  </Typography>
      
                  <div className="CardBox">
                    <div className="CardBoxWith">
                      <Typography className="companyInfoTitle4">
                        First Name:{" "}
                      </Typography>
                      <Typography className="companyInfoTitle3">
                        {" "}
                        {this.state.userInspectionData?.info?.firstName}
                      </Typography>
                    </div>
      
                    <div className="CardBoxWith">
                      <Typography className="companyInfoTitle4">
                        Middle Name:{" "}
                      </Typography>
                      <Typography className="companyInfoTitle3">
                        {" "}
                        {this.state.userInspectionData?.info?.middleName}
                      </Typography>
                    </div>
      
                    <div className="CardBoxWith">
                      <Typography className="companyInfoTitle4">
                        Last Name:{" "}
                      </Typography>
                      <Typography className="companyInfoTitle3">
                        {" "}
                        {this.state.userInspectionData?.info?.lastName}
                      </Typography>
                    </div>
      
                    <div className="CardBoxWith">
                      <Typography className="companyInfoTitle4">
                        Full Name Transliteration:{" "}
                      </Typography>
                      <Typography className="companyInfoTitle3">
                        {" "}
                        {this.state.userInspectionData?.info?.firstNameEn +
                          " " +
                          this.state.userInspectionData?.info?.lastNameEn}
                      </Typography>
                    </div>
      
                    <div className="CardBoxWith">
                      <Typography className="companyInfoTitle4">
                        Date of Birth:{" "}
                      </Typography>
                      <Typography className="companyInfoTitle3">
                        {" "}
                        {this.state.userInspectionData?.info?.dob}
                      </Typography>
                    </div>
      
                    <div className="CardBoxWith">
                      <Typography className="companyInfoTitle4">Country: </Typography>
                      <Typography className="companyInfoTitle3">
                        {" "}
                        {this.state.userInspectionData?.info?.country}
                      </Typography>
                    </div>
      
                    <div className="CardBoxWith">
                      <Typography className="companyInfoTitle4">Email: </Typography>
                      <Typography className="companyInfoTitle3">
                        {" "}
                        {this.state.userInspectionData?.email}
                      </Typography>
                    </div>
      
                    <div className="CardBoxWith">
                      <Typography className="companyInfoTitle4">
                        Phone Number:{" "}
                      </Typography>
                      <Typography className="companyInfoTitle3">
                        {" "}
                        {this.state.userInspectionData?.phone}
                      </Typography>
                    </div>
                  </div>
                </div>
      

                <div className="CardBoxParent">
                  <Typography
                    className="companyInfoTitle2"
                    style={{
                      fontWeight: "bold",
                      fontSize: "26px",
                      marginBottom: "8px",
                    }}
                  >
                    ID Card
                  </Typography>
      
                  <div className="CardBox">
                    <div className="CardBoxWith">
                      <Typography className="companyInfoTitle4">
                        First Name:{" "}
                      </Typography>
                      <Typography className="companyInfoTitle3">
                        {this.state.userInspectionData?.info?.idDocs[0].firstName}
                      </Typography>
                    </div>
      
                    <div className="CardBoxWith">
                      <Typography className="companyInfoTitle4">
                        Full Name Transliteration:{" "}
                      </Typography>
                      <Typography className="companyInfoTitle3">
                        {this.state.userInspectionData?.info?.idDocs[0].firstNameEn}
                      </Typography>
                    </div>
      
                    <div className="CardBoxWith">
                      <Typography className="companyInfoTitle4">
                        Last Name:{" "}
                      </Typography>
                      <Typography className="companyInfoTitle3">
                        {this.state.userInspectionData?.info?.idDocs[0].lastName}
                      </Typography>
                    </div>
      
                    <div className="CardBoxWith">
                      <Typography className="companyInfoTitle4">
                        Last Name Transliteration:{" "}
                      </Typography>
                      <Typography className="companyInfoTitle3">
                        {this.state.userInspectionData?.info?.idDocs[0].lastNameEn}
                      </Typography>
                    </div>
      
                    <div className="CardBoxWith">
                      <Typography className="companyInfoTitle4">Number: </Typography>
                      <Typography className="companyInfoTitle3">
                        {this.state.userInspectionData?.info?.idDocs[0].number}
                      </Typography>
                    </div>
      
                    <div className="CardBoxWith">
                      <Typography className="companyInfoTitle4">
                        Date of Birth:{" "}
                      </Typography>
                      <Typography className="companyInfoTitle3">
                        {this.state.userInspectionData?.info?.idDocs[0].dob}
                      </Typography>
                    </div>
      
                    <div className="CardBoxWith">
                      <Typography className="companyInfoTitle4">
                        Expiry Date:{" "}
                      </Typography>
                      <Typography className="companyInfoTitle3">
                        {this.state.userInspectionData?.info?.idDocs[0].validUntil}
                      </Typography>
                    </div>
                  </div>
                </div>
              </div>
            </MainContent>
          </div>
        );
      };
  // Customizable Area End

  render() {    
    return (
      // Customizable Area Start
      <>
      {
        this.state.role === "company" ? <Headers sliderButton={this.state.sideBarClose}
        logOutToogle={this.logOutBtn} 
        data-testId="logoutTestId"
        onToggleClick={this.handleSliderButton}
        goToScreen = {(screenName:string)=>this.goToScreen(screenName,true)}
        isRouteData="CustomFormUserList"
        >
       {
       this.state.checkforview ?
       this.renderPerticulerDetails2():
        this.renderEmployeForm() 
       }
      </Headers> 
      :

      <EmployeHeader
      sliderButton={this.state.sideBarClose}
        logOutToogle={this.logOutBtn} 
        data-testId="logoutTestId"
        onToggleClick={this.handleSliderButton}
        goToScreen = {(screenName:string)=>this.goToScreen(screenName,true)}
        isRouteData="CustomFormUserList"
      >
     {
        this.state.checkforview ?
        this.renderPerticulerDetails2():
        this.renderEmployeForm() 
       }
      </EmployeHeader>
      }
    </>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
const StyledTableContainer = styled(TableContainer)({
    width: "100%",   
    height: "auto",
    gap: "24px",
    opacity: "0px",

    "& .userImg": {
        width: "48px",
        height: "48px",
        borderRadius: "48px"
    },
    "@media (max-width: 1200px)": {
        marginTop: "110px"
},   

    "@media (max-width: 768px)": {
            marginTop: "110px"
    },  
   
});

const StyledTable = styled(Table)({
    backgroundColor: "#18191b",
    "& .MuiTableCell-root": {
        borderBottom: "1px solid #272B30",
    },
    "& .MuiTableCell-head": {
        color: "#fff",
        width: "max-content",
    },
    "& .MuiTableCell-body": {
        color: "rgba(111, 118, 126, 1)",
        fontSize: "14px",
        fontWeight: 600
    },
    "& .nameTxt": {
        color: "rgba(252, 252, 252, 1)",
        fontWeight: 700,
        fontFamily: 'Lato',
        height: "24px",
        width: "114px",
        fontSize: "15px",
        cursor:"pointer"
    },
    "& .dobTableTxt": {
        fontWeight: 600,
        color: "rgba(111, 118, 126, 1)",
        fontSize: "14px",
        fontFamily: 'Lato',
        height: "81px",
        width: "24px"
    },
    "& .countryTableText": {
        color: "rgba(111, 118, 126, 1)",
        fontSize: "14px",
        fontWeight: 600,
        width: "84px",
        height: "24px",
        fontFamily: 'Lato',
        whiteSpace:"nowrap" 
    },
    "& .passportTextTable": {
        width: "81px",
        height: "24px",
        color: "rgba(111, 118, 126, 1)",
        fontSize: "14px",
        fontFamily: 'Lato',
        fontWeight: 600,
    },
    "& .nationalityText": {
        width: "80px",
        color: "rgba(111, 118, 126, 1)",
        fontSize: "14px",
        fontWeight: 600,
        fontFamily: 'Lato',
        height: "24px"
    },
    "& .addressText": {
        color: "rgba(111, 118, 126, 1)",
        fontSize: "14px",
        fontWeight: 600,
        fontFamily: 'Lato',
        width: "249px",
        height: "48px"
    },
    "& .lato": {
        fontFamily: 'Lato'
    },
    "& .dobTableCell": {
        width: "78px",
        fontFamily: 'Lato',
        color: 'rgba(211, 211, 211, 1)',
        height: '16px',
        gap: '0px',
        opacity: "0px",
        fontWeight: 600,
        whiteSpace:"nowrap"     

    },
    "& .nameTableCell":{
        width: "36px",
        fontFamily: 'Lato',
        color: 'rgba(211, 211, 211, 1)',
        height: '16px',
        gap: '0px',
        opacity: "0px",
        fontWeight: 600,
        paddingLeft:"35px"
    },
    "& .nationalityTableCell":{
        color: 'rgba(211, 211, 211, 1)',
        width: "68px",
        fontFamily: 'Lato',
        height: '16px',
        gap: '0px',
        opacity: "0px",
        fontWeight: 600
    },
    "& .countryTableCell":{
        fontFamily: 'Lato',
        color: 'rgba(211, 211, 211, 1)',
        height: '16px',
        width: "50px",
        gap: '0px',
        opacity: "0px",
        fontWeight: 600
    },
    "& .addressTableCell":{
        width: "50px",
        color: 'rgba(211, 211, 211, 1)',
        fontFamily: 'Lato',
        height: '16px',
        gap: '0px',
        opacity: "0px",
        fontWeight: 600
    },
    "& .passportTableCell":{
        width: "106px",
        fontFamily: 'Lato',
        fontWeight: 600,
        color: 'rgba(211, 211, 211, 1)',
        height: '16px',
        gap: '0px',
        opacity: "0px",
    },
    "& .documentTableCell":{
        gap: '0px',
        width: "70px",
        fontFamily: 'Lato',
        color: 'rgba(211, 211, 211, 1)',
        height: '16px',
        opacity: "0px",
        fontWeight: 600
    },
    "& .statusTableCell":{
        width: "70px",
        fontFamily: 'Lato',
        color: 'rgba(211, 211, 211, 1)',
        fontWeight: 600,
        height: '16px',
        gap: '0px',
        opacity: "0px",
    },
    "& .documentNameText": {
        fontFamily: 'Lato',
        fontWeight: 600,
        color: "rgba(111, 118, 126, 1)"
    }
})

const UserName = styled(Box)({
    display: "flex",
    alignItems: "center",
    gap: "12px"
})

const TableDocWrapper = styled(Box)({
    "& .singleDoc": {
        display: "flex",
        alignItems: "center",
        gap: "8px",
    },
    "& .downloadBtn": {
        width: "16px",
        height: "16px"
    }
})

const StatusWrapper = styled(Box)({
    display: "flex",
    alignItems: "center",
    gap: "8px",
    "& .approvedBtn": {
        backgroundColor: '#41d613',
        color: 'rgba(36, 36, 36, 1)',
        width: "76px",
        height:"28px",
        textTransform: "none",
        borderRadius: "0px",
        fontFamily: "Lato",
        fontWeight: 600,
        fontSize: "12px",
        lineHeight:"24px"
    },
    "& .pendingBtn": {
        backgroundColor: 'yellow',
        color: 'rgba(36, 36, 36, 1)',
        width: "80px",
        height:"28px",
        extTransform: "none",
        marginLeft: "33px",
        textTransform: "none",
        borderRadius: "0px",
        fontFamily: "Lato",
        fontWeight: 600,
        fontSize: "12px",
        lineHeight:"24px"
    },
    "& .rejectedBtn": {
        backgroundColor: 'red',
        color: 'rgba(36, 36, 36, 1)',
        width: "98px",
        height:"28px",
        textTransform: "none",
        borderRadius: "0px",
        fontFamily: "Lato",
        fontWeight: 600,
        fontSize: "12px",
        lineHeight:"24px"
    },
    "& .needClarificationBtn": {
        backgroundColor: "#FF7A01",
        color: "rgba(36, 36, 36, 1)",
        width: "200px",
        height:"36px",
        textTransform: "none",
        borderRadius: "0px",
        fontFamily: "Lato",
        fontWeight: 400,
        fontSize: "14px",
        lineHeight:"24px"
    },
    "& .downloadBtn": {
        width: "16px",
        height: "16px"
    },
    "& .downloadLargeBtn": {
        cursor:"pointer"
    },
    "& .downloadLargeBtn2": {
        cursor:"pointer",   
    }
})

const PageTop = styled(Box)({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "44px",
    paddingBottom: "20px",
 
    "& .searchContainer": {
        display: "flex",
        justifyContent: "flex-start",
        gap: "15px",
        outline: "none",
        alignItems: "center",
        border: "none",
        borderRadius: "0px",
        
        "& .searchInputContainer": {
            position: "relative",

            "& .searchStatusContainer": {
                position: "absolute",
                padding: "16px",
                display: "flex",
                justifyContent: "space-between",
                gap: "10px",
                backgroundColor: "rgba(36, 36, 36, 1)",
                width: "100%",
                listStyle: "none",
                margin: 0,
                height: "10px",

                "& .searchStatus": {
                    padding: "12px",
                    fontSize: "8px",
                    fontWeight: 600,
                    lineHeight: "20px",
                    fontFamily: "lato",
                    textTransform: "capitalize",
                    cursor: "pointer",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                },

                "& .searchApprovedStatus": {
                    border: "1px solid rgba(0, 255, 0, 1)",
                    color: "rgba(0, 255, 0, 1)",
                },
                "& .searchPendingStatus": {
                    border: "1px solid rgba(251, 212, 0, 1)",
                    color: "rgba(251, 212, 0, 1)",
                },
                "& .searchRejectedStatus": {
                    border: "1px solid rgba(255, 25, 56, 1)",
                    color: "rgba(255, 25, 56, 1)",
                },
                "& .searchClarificationStatus": {
                    border: "1px solid rgba(255, 122, 1, 1)",
                    color: "rgba(255, 122, 1, 1)",
                },
            },
        },
    },

    "& .searchInputfield": {
        backgroundColor: "#505050",
        color: "#fff",
        height: "44px",
        fontSize: "16px",
        fontWeight: 400,
        fontFamily: "Lato",
        width: "405px",
        outline: "none",
        border: "none",
        borderRadius: "0",
        padding: "10px",

        "& .MuiInputBase-input": {
            width: "100%",
            height: "24px",
            fontFamily: "Lato",
        },
    },

    "& .MuiOutlinedInput-inputAdornedStart": {
        color: "#A7A7A7",
        height: "7px",
    },

    "& .searchIcon": {
        color: "#A7A7A7",
        marginRight: "20px",
        width: "17.05px",
        height: "17.06px",
        cursor: "pointer",
    },

    "& .crossIcon": {
        color: "#A7A7A7",
        marginRight: "20px",
        width: "18.05px",
        height: "18.06px",
        cursor: "pointer",
    },

    "& .searchBtn": {
        height: "44px",
        width: "181px",
        backgroundColor: "#00FF00",
        color: "#242424",
        borderRadius: "0px",
        textTransform: "none",
    },

    "& .pageMain": {
        height: "44px",
        width: "1333px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },


    "@media (max-width: 1024px)": {
      
        "& .pageMain": {
            width: "100%",
            padding: "0 20px",
        },
        "& .searchBtn": {
            width: "150px",
        },
    },


    "@media (max-width:1200px)": {
        "& .searchContainer": {
            alignItems: "flex-start",
            height:'100%',
        },
        "& .pageMain": {
            flexDirection: "column",
            alignItems: "flex-start",
        },
     
        flexDirection: "column",
    },


    "@media (max-width: 768px)": {
        flexDirection: "column",
        "& .pageMain": {
            flexDirection: "column",
            alignItems: "flex-start",
        },
        "& .searchContainer": {
            flexDirection: "column",
            alignItems: "flex-start",
        },
        "& .searchInputfield": {
            width: "405px",
        },
        "& .searchBtn": {
            width: "100%",
        },
         "& .statusDiv":{
            height:'83px'
        },
    },

    "@media (max-width:520px)":{

        "& .searchInputfield": {
            width: "100%",
        },
    },

    "@media (max-width: 480px)": {
        "& .searchInputfield": {
            height: "36px",
            fontSize: "14px",
        },
        "& .searchBtn": {
            width: "100%",
            fontSize: "14px",
        },
    },
    "@media (max-width: 440px)": {
        "& .searchInputfield": {
            width: "75%",
          
        },
        "& .searchBtn": {
            width: "75%",
        },
         "& .statusDiv":{
           width:'75%'
        },
        "& .searchContainer": {
            flexDirection: "column",
            alignItems: "center",
        },
       
        "& .searchInputContainer":{
            flexDirection: "column",
            display:'flex',
            alignItems:'center'
        }
    
      
    },
});

const MainContent = styled(Box)({  
    margin: "0 auto",
    padding: "20px",
    backgroundColor: "rgba(26, 29, 31, 1)",
    fontFamily: 'Lato',
    position: "relative",   
    "& .paginationButtons":{
        display: "flex",
        justifyContent: "center",
        marginTop:"10px",
        "& .MuiPagination-ul li":{
            backgroundColor: "grey"
        }
    },
    "& .loaderWrapper":{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        color: "#fff"
    },
    "& .headerText": {
        display: "flex",
        color: "rgba(252, 252, 252, 1)",
        fontSize: "40px",
        fontWeight: 600,
        fontFamily: 'Lato',
        width: "100%",
        maxWidth: "450px",
        height: "auto",
        marginBottom: "20px",
        lineHeight: "1.2",
        textAlign: "left",
    },
    "@media (max-width: 768px)": {
        "& .headerText": {
            fontSize: "30px",
            maxWidth: "100%",
            textAlign: "center",
        },

    },
    "@media (max-width: 1200px)": {
        "& .headerText": {
            fontSize: "30px",
            maxWidth: "100%",
            textAlign: "center",
        },

    },
    "@media (max-width: 480px)": {
        "& .headerText": {
            fontSize: "24px",
            textAlign: "center",
            marginBottom: "15px",
        },
    },
    "& .companyInfoTitle": {
        fontFamily: 'Lato',
        fontSize: '32px',
        fontWeight: 600,   
        textTransform: 'none',
        color:"#F8FAFC"
      },
      "& .companyInfoTitle3": {
        fontFamily: 'Lato',
        fontSize: '18px',
        fontWeight: 600,  
        textTransform: 'none',
        color:'#A7A7A7',
        wordWrap:"break-word"
      },
      "& .companyInfoTitle2": {
        fontFamily: 'Lato',
        fontSize: '21px',
        fontWeight: 600,  
        textTransform: 'none',
        color:"#F8FAFC"
      },
      "& .CardBoxParent": {
        display: "flex",
        flexDirection: "column",
        // gap: 8,
        padding: "35px",               
        color: "#fff",  
        borderBottom:"1px solid green",
        flexWrap:"wrap",  
      },
      "& .CardBox": {
        padding:"10px",
        backgroundColor:"#18191b",
        display:"flex", 
        flexWrap:"wrap",    
        gap:30
      },
      "& .CardBoxWith": {
          width:'22%',          
      },
      "@media (max-width: 1000px)": {
        "& .CardBoxWith": {
          width:'32%',          
      },
    },
      "& .companyInfoTitle4": {
        fontFamily: 'Lato',
        fontSize: '18px',
        fontWeight: 600,  
        textTransform: 'none',
        color:"#F8FAFC",
      },
      "& .backBtn": {
        height: "40px",
        width: "40px",
        color: "white"
      },
})
const customStyles = {
    noDataFoundDiv: {
      textAlign: 'center'
    } as React.CSSProperties,
    noDataFoundBox: {
      display: "flex", 
      justifyContent: "center", 
      margin: "30px 0px",
    },
    noDataFoundText: {
      fontSize: "16px",
      fontWeight: 500,
      fontFamily: "Open Sans",
      color: "#390689"
    },
  }
export const PagginationBox = styled(Box)({
    marginLeft: "auto",
    marginTop: "20px",
    marginBottom:"35px",
    width: "max-content",
    "& .MuiSvgIcon-root": {
        fill: "grey"
    },
    "& .MuiPaginationItem-page": {
        backgroundColor: "unset",
        color: "rgba(0, 255, 0, 1)",
        borderBottom: "unset !important",
    },
    "& .MuiPaginationItem-page.Mui-selected": {
        border: "1px solid grey !important"
    },
    "& .MuiPaginationItem-page.Mui-selected::before": {
        backgroundColor: "transparent !important",
    },
    "& .MuiPagination-ul .MuiButtonBase-root": {
        position: "relative",
        borderRadius: "50%",
        border: "none"
    },

    "& .MuiPagination-ul .MuiButtonBase-root::before": {
        content: "",
    },
    "& .MuiPagination-ul li:first-child .MuiButtonBase-root::before": {
        backgroundColor: "transparent !important",
    },
    "& .MuiPagination-ul li:last-child .MuiButtonBase-root::before": {
        backgroundColor: "transparent !important",
    },
    "& .showingTxt": {
        fontFamily: "Plus Jakarta Sans",
        fontSize: "14px",
        color: "#212121"
    },
    "@media (max-width: 768px)": {
        justifyContent: "center",
        margin: "20px auto",
    },
});
// Customizable Area End
