import React from "react";
// Customizable Area Start
import {
  Box,
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Popover,
  TableContainer,
  MenuItem,
  Input,
  IconButton
} from "@mui/material";
import Headers from '../../../components/src/Headers.web';
import { styled } from '@material-ui/styles';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import SearchIcon from '@material-ui/icons/Search';
import {  ArrowBack } from '@mui/icons-material';
import ArrowIcon from '@material-ui/icons/ArrowBack';
import { configJSON } from "./InviteEmployeeController.web";
import Pagination from '@mui/material/Pagination';
import Skeleton from '@material-ui/lab/Skeleton';
const profileImg2 = require("../assets/blankProfile.jpeg");
import ClearIcon from '@material-ui/icons/Clear';
import DownloadPdf from "../../../components/src/DownloadPdf";
import {  pdfIcon, downloadIcon } from "./assets";
// Customizable Area End
import InviteEmployeeController, {
  Props
} from "./InviteEmployeeController.web";

export default class ViewKycChecklist extends InviteEmployeeController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start


  getStatusButton = (status: string) => {
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);
    const rejectText = this.state.rejectedText;
    switch (status) {
        case 'GREEN':
            return (
                <Button className="approvedBtn">
                    {configJSON.approvedText}
                </Button>
            );
        case 'Pending':
            return (
                <Button className="pendingBtn">
                    {configJSON.pendingText}
                </Button>
            );
        case 'RED':
            return (
                <>
                    <Button
                        className={rejectText ? "rejectedBtn" : "needClarificationBtn"}
                        data-testId="rejectTestId"
                        onClick={this.handleClick}
                        endIcon={<ExpandMoreIcon />}
                    >
                        {rejectText ? configJSON.rejectedText : configJSON.clearificationText}
                    </Button>
                    <Popover
                        open={open}
                        anchorEl={anchorEl}
                        data-testId="closeAnchorTestId"
                        onClose={this.handleClose}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                        PaperProps={{
                            style: {
                                marginTop: "10px",
                                backgroundColor: !this.state.rejectedText ? "red" : "#FF7A01",
                                color: "black",

                            },
                        }}
                    >
                        <MenuItem className="needClaritiy" data-testId = "needClarificationTestId" onClick={this.handleNeedClarification}> {!rejectText ? configJSON.rejectedText : configJSON.clearificationText}
                        </MenuItem>
                    </Popover>
                </>
            );
        default:
            return null;
    }
  };

  renderSkeletonLoader=()=>{

      const Arr=[1,2,3,4,5,6,7,8];
      return(
        <TableBody>
    
          {
    
          Arr.map(i=>(
          <TableRow>
    {
      Arr.map(i=>(
        <TableCell>
        <Skeleton style={{background: ' rgb(113, 108, 108)', borderRadius: '6px'}}  width={200} variant="text" />
        </TableCell>
      ))
    }
          </TableRow>
          ))
    }
    
        </TableBody>
      )
    }


  renderNoDataFoundEmployeeViewKyc=()=>{
    return(
    
<TableCell colSpan={8}>
          <div style={{display:'flex',flexDirection:'column',padding:"30px 0px",alignItems:'center',justifyContent:'center',
          background: '#242424'}}>


          <img src={require("../assets/image_.svg")} style={{width:'20%'}}  />
<p style={{color:'white', fontSize:'20px',fontWeight:'bold'}}>No Data Found</p>

     </div> 
     </TableCell>
    )

    
  }


  renderContent = () => {
    const { userDataLoading, userData } = this.state;
  
    if (userDataLoading) {
      return this.renderSkeletonLoader();
    }
  
    if (userData?.length === 0) {
      return this.renderNoDataFoundEmployeeViewKyc();
    }
  
    return this.renderPaginationCell();
  }
  

  handleStatusBlockViewKyc=()=>{
    return(
    <div 
    style={{position:'relative'}} className="statusDiv"
    >

            <Box style={{padding: '10px',position:'absolute', background:'#505050',width:'100%',boxSizing:'border-box'}} >
            <Button   className={this.state.highlightStatus && this.state.status === 'green' ? 'highlighted1' : 'nothighlighted1'} data-testId="statusApproved" onClick={()=>this.handleStatusClick('green')}>Approved</Button>
             <Button   className={this.state.highlightStatus && this.state.status === 'pending' ? 'highlighted2' : 'nothighlighted2'}  data-testId="statuspending" onClick={()=>this.handleStatusClick('pending')}>Pending</Button>
             <Button   className={this.state.highlightStatus && this.state.status === 'red' ? 'highlighted3' : 'nothighlighted3'} data-testId="statusrejected" onClick={()=>this.handleStatusClick('red')}>Rejected</Button>
             <Button    className={this.state.highlightStatus && this.state.status === 'need clarification' ? 'highlighted4' : 'nothighlighted4'} data-testId="statusneedclarification" onClick={()=>this.handleStatusClick('need clarification')}>Need clarification</Button>
                    </Box>
                    <style>
{`
.highlighted1 {
border: 1px solid rgba(0, 255, 0, 1);
background: rgba(0, 255, 0, 1);
color: white;
margin-right: 5px;
margin-bottom: 5px;
text-transform: none; 
cursor: pointer;
}

.highlighted1:hover {
background: rgba(0, 255, 0, 1);
color: white;
margin-right: 5px;
margin-bottom: 5px;
text-transform: none; 
cursor: pointer;
}

.highlighted2:hover {
  background: rgba(251, 212, 0, 1);
  color: white;
  margin-right: 5px;
  margin-bottom: 5px;
  text-transform: none; 
  cursor: pointer;
  }
  
  .nothighlighted2 {
  border: 1px solid rgba(251, 212, 0, 1);
  color: rgba(251, 212, 0, 1);
  margin-right: 5px;
  margin-bottom: 5px;
  text-transform: none; 
  cursor: pointer;
  }


.nothighlighted1 {
border: 1px solid rgba(0, 255, 0, 1);
color: rgba(0, 255, 0, 1);
margin-right: 5px;
margin-bottom: 5px;
text-transform: none; 
cursor: pointer;
}

.highlighted2 {
border: 1px solid rgba(251, 212, 0, 1);
background: rgba(251, 212, 0, 1);
color: white;
margin-right: 5px;
margin-bottom: 5px;
text-transform: none; 
cursor: pointer;
}



.highlighted4 {
  border: 1px solid rgba(255, 122, 1, 1);
  background: rgba(255, 122, 1, 1);
  color: white;
  margin-right: 5px;
  margin-bottom: 5px;
  text-transform: none; 
  cursor: pointer;
  }
  
  .highlighted4:hover {
  background: rgba(255, 122, 1, 1);
  color: white;
  margin-right: 5px;
  margin-bottom: 5px;
  text-transform: none; 
  cursor: pointer;
  }
  
  .nothighlighted4 {
  border: 1px solid rgba(255, 122, 1, 1);
  color: rgba(255, 122, 1, 1);
  margin-right: 5px;
  margin-bottom: 5px;
  text-transform: none; 
  cursor: pointer;
  }



.highlighted3 {
border: 1px solid rgba(255, 25, 56, 1);
background: rgba(255, 25, 56, 1);
color: white;
margin-right: 5px;
margin-bottom: 5px;
text-transform: none; 
cursor: pointer;
}

.highlighted3:hover {
background: rgba(255, 25, 56, 1);
color: white;
margin-right: 5px;
margin-bottom: 5px;
text-transform: none;
cursor: pointer;
}

.nothighlighted3 {
border: 1px solid rgba(255, 25, 56, 1);
color: rgba(255, 25, 56, 1);
margin-right: 5px;
margin-bottom: 5px;
text-transform: none; 
cursor: pointer;
}


`}
</style>
                    </div>
    )
  }


    renderPaginationCell = () => {
      return (
          <TableBody>
              {
                  this.state.userData?.length > 0
                      ? this.state.userData.map((singleData) => {
                          return (
                              <TableRow key={singleData?.id}>
                                  <TableCell>
                                      <UserName>
                                          <img 
                                              src={singleData.attributes?.profile_picture || profileImg2 }
                                              alt="no image" 
                                              className="userImg" 
                                          />
                                          <Typography className="nameTxt" data-testId="backbuttontestID1"  onClick={()=>this.getDetailsofUser(singleData?.attributes?.applicant_id)} >
                                          {this.formatNameOfEmployee(singleData.attributes?.firstName)}
                                          </Typography>
                                      </UserName>
                                  </TableCell>
                                  <TableCell className="dobTableTxt">
                                      {singleData?.attributes?.dob}
                                  </TableCell>
                                  <TableCell className="nationalityText">
                                      {singleData?.attributes?.nationality}
                                  </TableCell>
                                  <TableCell className="countryTableText">
                                      {singleData?.attributes?.country}
                                  </TableCell>
                                  <TableCell className="addressText">
                                      {singleData?.attributes?.addresses}
                                  </TableCell>
                                  <TableCell className="passportTextTable">
                                      {singleData?.attributes?.passport_number}
                                  </TableCell>
                                  <TableCell>
                                      {
                                          [`${singleData?.attributes?.passport}`].map((document: string) => (
                                            <TableDocWrapper key={document}>
                                                <Box className="singleDoc">
                                               
                                                  
                                                    <DownloadPdf downloadIcon={downloadIcon} document={document} setDownloadPdfLoader={this.setDownloadPdfLoader} />
                                                    <img src={pdfIcon} />
                                                    <Typography className="documentNameText">{configJSON.passportPdf}</Typography>
                                                </Box>
                                            </TableDocWrapper>
                                        ))
                                      }
                                  </TableCell>
                                  <TableCell>
                                      <StatusWrapper style={{ width: "130px" }}>                                          
                                        
                                               {this.getStatusButton(singleData?.attributes?.status?.review_result?.reviewAnswer)}
                                      </StatusWrapper>
                                  </TableCell>
                              </TableRow>
                          )
                      })
                      :
                      <TableRow>
                          <TableCell colSpan={8} style={customStyles.noDataFoundDiv}>
                              <Box style={customStyles.noDataFoundBox}>
                                  <Typography style={customStyles.noDataFoundText}>
                                      No Data Found
                                  </Typography>
                              </Box>
                          </TableCell>
                      </TableRow>
              }
          </TableBody>
      );
  };

  renderDashboardForm = () => {
    return (
      <Wrapper style={{overflowY:'auto'}}>
         <Box style={{ padding: "0px", display:"flex" }}>
            <IconButton edge="start">
              <ArrowBack data-testId="backButtonTest_view" onClick={() => this.goToScreen("PeopleManagement")} style={{width: "40px",height: "40px", color: "white"}}/>
            </IconButton>
            <Typography className="home">{configJSON.homeText}</Typography>
        </Box>
        <Box className="contentBox">
          <Typography className="tableHeader">
            {configJSON.userInformation}
          </Typography>
          <PageTop className="pageMain">
            
                      <Box className="searchContainer">
                          <Box className="searchInputContainer">
                              <Input
                                  className="searchInputfield"
                                  fullWidth
                                  placeholder="Search"
                                  disableUnderline
                                  name="cardNumber"
                                  data-testId="cardNumberTestId"
                                  onChange={this.searchHandleChange}
                                  onClick={this.handleToggleStatus}
                                  value={this.state.searchValue}
                                  startAdornment={
                                      <SearchIcon className="searchIcon" />
                                  }
                                  endAdornment={
                                      <ClearIcon  onClick={this.clearData} className="crossIcon" />
                                  }
                              />


{
                    this.state.showStatusDiv &&
               
                  this.handleStatusBlockViewKyc()
        
}

                          </Box>
                      </Box>     
          </PageTop>
        </Box>
        <StyledTableContainer className="StyledTableContainer" style={{ overflowX: this.state.userDataLoading ? 'hidden' : 'auto'}} >
                    <StyledTable>
                        <TableHead>
                            <TableRow>
                                {

                                    this.state.tableHeaderData.filter((hData: { [key: string]: string }) => {
                                        if (this.state.searchValue) {
                                            return hData.name === "Name" || hData.name === "Date of Birth" || hData.name === "Nationality" || hData.name === "Status"
                                        }
                                        return hData.name.includes("");
                                    }).map((hData: { [key: string]: string }) => <TableCell className={hData.className} key={hData.name}>{hData.name}</TableCell>)
                                }
                            </TableRow>
                        </TableHead>                   
                        {
                       this.renderContent()
                    }
                    </StyledTable>
                
        </StyledTableContainer>
        {
            this.handleLoader() &&
            <PagginationBox>
                 <Pagination data-testId="paginationTestId" count={Math.ceil((this.state.viewKycTotalCount || 0)/ 10)} variant="outlined" shape="rounded" onChange={this.handlePageChange} page={this.state.viewKycCurrentPage} />
            </PagginationBox>
        }    
      </Wrapper>
    )
  }
  getStatusCode = (status: string)=> {        
    switch (status) {
        case 'GREEN':
            return (
                <Typography className="companyInfoTitle3" style={{color:"#41d613"}} > APPROVED </Typography>
            );
        case 'Pending':
            return (
                <Typography className="companyInfoTitle3" style={{color:"yellow"}}> PENDING </Typography>
            );
        case 'RED':
            return (
               <Typography className="companyInfoTitle3" style={{color:"red"}}> REJECTED </Typography>
            );
        default:
            return "No Status";
    }
};
  renderPerticulerDetails2 = () => {
    return (
      <div style={{ height: "100%" }}>
        <MainContent
          style={{
            overflowY: "auto",
            height: "calc(100% - 71px)",
            boxSizing: "border-box",
          }}
        >
          <div style={{ display: "flex" }}>
            <IconButton
              onClick={this.checkforviewFun}
              data-testId="backbuttontestID"
              className="downloadLargeBtn"
            >
              <ArrowIcon className="backBtn" />
            </IconButton>
  
            <Typography className="companyInfoTitle">
              Verification Details
            </Typography>
          </div>
  
          <div
            style={{
              padding: "46px",
              backgroundColor: "#18191b",
            }}
          >
      
            <div className="CardBoxParent">
              <Typography
                className="companyInfoTitle2"
                style={{
                  fontWeight: "bold",
                  fontSize: "26px",
                  marginBottom: "8px",
                }}
              >
                Overview
              </Typography>
  
              <div className="CardBox">
                <div className="CardBoxWith">
                  <Typography
                    className="companyInfoTitle2"
                    style={{ fontWeight: "bold" }}
                  >
                    Name:
                  </Typography>
  
                  <Typography className="companyInfoTitle3">
                    {" "}
                    {this.state.userInspectionData?.info?.firstName}
                  </Typography>
                </div>
  
                <div className="CardBoxWith">
                  <Typography
                    className="companyInfoTitle2"
                    style={{ fontWeight: "bold" }}
                  >
                    Inspection report:
                  </Typography>
  
                  <Typography className="companyInfoTitle3">
                    {" "}           
                    {this.state.userInspectionData?.createdAt?.split(" ")[0]}
                  </Typography>
                </div>
  
                <div className="CardBoxWith">
                  <Typography
                    className="companyInfoTitle2"
                    style={{ fontWeight: "bold"}}
                  >
                    Creadted for:
                  </Typography>
  
                  <Typography
                    className="companyInfoTitle3"
                  >
                    {" "}
                    {this.state.userInspectionData?.clientId}
                  </Typography>
                </div>
  
                <div className="CardBoxWith">
                  <Typography
                    className="companyInfoTitle2"
                    style={{ fontWeight: "bold"}}
                  >
                    Verification Status:
                  </Typography>
                  <Typography
                    className="companyInfoTitle3"            
                  >
                    {this.getStatusCode(this.state.userInspectionData?.review?.reviewResult.reviewAnswer  ?? "No Status") }
                  </Typography>
                </div>

              </div>
            </div>
   
            <div className="CardBoxParent">
              <Typography
                className="companyInfoTitle2"
                style={{
                  fontWeight: "bold",
                  fontSize: "26px",
                  marginBottom: "8px",
                }}
              >
                Profile Data
              </Typography>
  
              <div className="CardBox">
                <div className="CardBoxWith">
                  <Typography className="companyInfoTitle4">ID: </Typography>
                  <Typography className="companyInfoTitle3" style={{fontSize:"19px",wordWrap:"break-word"}}>  
                    {this.state.userInspectionData?.id}
                  </Typography>
                </div>
  
                <div className="CardBoxWith">
                  <Typography className="companyInfoTitle4">
                    External User ID:{" "}
                  </Typography>
                  <Typography className="companyInfoTitle3" style={{fontSize:"19px",wordWrap:"break-word"}}>
                    {" "}
                    {this.state.userInspectionData?.externalUserId}
                  </Typography>
                </div>
  
                <div className="CardBoxWith">
                  <Typography className="companyInfoTitle4">
                    Created for:{" "}
                  </Typography>
                  <Typography className="companyInfoTitle3">
                    {" "}
                    {this.state.userInspectionData?.createdAt?.split(" ")[0]}
                  </Typography>
                </div>
  
                <div className="CardBoxWith">
                  <Typography className="companyInfoTitle4">
                    Profile created:{" "}
                  </Typography>
                  <Typography className="companyInfoTitle3">
                    {" "}
                    {this.state.userInspectionData?.clientId}
                  </Typography>
                </div>
  
                <div className="CardBoxWith">
                  <Typography className="companyInfoTitle4">
                    Requested document:{" "}
                  </Typography>
                  {this.state.userInspectionData?.requiredIdDocs?.docSets[0].types.map(
                    (data, index) => {
                      return (
                        <Typography className="companyInfoTitle3">
                          {" "}
                          {data}
                        </Typography>
                      );
                    }
                  )}
                </div>
              </div>
            </div>
  
   
            <div className="CardBoxParent">
              <Typography
                className="companyInfoTitle2"
                style={{
                  fontWeight: "bold",
                  fontSize: "26px",
                  marginBottom: "8px",
                }}
              >
                Provided Data
              </Typography>
  
              <div className="CardBox">
                <div className="CardBoxWith">
                  <Typography className="companyInfoTitle4">
                    First Name:{" "}
                  </Typography>
                  <Typography className="companyInfoTitle3">
                    {this.state.userInspectionData?.fixedInfo?.firstName}
                  </Typography>
                </div>
  
                <div className="CardBoxWith">
                  <Typography className="companyInfoTitle4">
                    Full Name Transliteration:{" "}
                  </Typography>
                  <Typography className="companyInfoTitle3">
                    { this.state.userInspectionData?.fixedInfo?.firstNameEn}
                  </Typography>
                </div>
  
                <div className="CardBoxWith">
                  <Typography className="companyInfoTitle4">
                    Date of Birth:{" "}
                  </Typography>
                  <Typography className="companyInfoTitle3">
                    {this.state.userInspectionData?.fixedInfo?.dob}
                  </Typography>
                </div>
  
                <div className="CardBoxWith">
                  <Typography className="companyInfoTitle4">Country: </Typography>
                  <Typography className="companyInfoTitle3">
                    {this.state.userInspectionData?.fixedInfo?.country}
                  </Typography>
                </div>
              </div>
            </div>
  

            <div className="CardBoxParent">
              <Typography
                className="companyInfoTitle2"
                style={{
                  fontWeight: "bold",
                  fontSize: "26px",
                  marginBottom: "8px",
                }}
              >
                Personal Data
              </Typography>
  
              <div className="CardBox">
                <div className="CardBoxWith">
                  <Typography className="companyInfoTitle4">
                    First Name:{" "}
                  </Typography>
                  <Typography className="companyInfoTitle3">
                    {" "}
                    {this.state.userInspectionData?.info?.firstName}
                  </Typography>
                </div>
  
                <div className="CardBoxWith">
                  <Typography className="companyInfoTitle4">
                    Middle Name:{" "}
                  </Typography>
                  <Typography className="companyInfoTitle3">
                    {" "}
                    {this.state.userInspectionData?.info?.middleName}
                  </Typography>
                </div>
  
                <div className="CardBoxWith">
                  <Typography className="companyInfoTitle4">
                    Last Name:{" "}
                  </Typography>
                  <Typography className="companyInfoTitle3">
                    {" "}
                    {this.state.userInspectionData?.info?.lastName}
                  </Typography>
                </div>
  
                <div className="CardBoxWith">
                  <Typography className="companyInfoTitle4">
                    Full Name Transliteration:{" "}
                  </Typography>
                  <Typography className="companyInfoTitle3">
                    {" "}
                    {this.state.userInspectionData?.info?.firstNameEn +
                      " " +
                      this.state.userInspectionData?.info?.lastNameEn}
                  </Typography>
                </div>
  
                <div className="CardBoxWith">
                  <Typography className="companyInfoTitle4">
                    Date of Birth:{" "}
                  </Typography>
                  <Typography className="companyInfoTitle3">
                    {" "}
                    {this.state.userInspectionData?.info?.dob}
                  </Typography>
                </div>
  
                <div className="CardBoxWith">
                  <Typography className="companyInfoTitle4">Country: </Typography>
                  <Typography className="companyInfoTitle3">
                    {" "}
                    {this.state.userInspectionData?.info?.country}
                  </Typography>
                </div>
  
                <div className="CardBoxWith">
                  <Typography className="companyInfoTitle4">Email: </Typography>
                  <Typography className="companyInfoTitle3">
                    {" "}
                    {this.state.userInspectionData?.email}
                  </Typography>
                </div>
  
                <div className="CardBoxWith">
                  <Typography className="companyInfoTitle4">
                    Phone Number:{" "}
                  </Typography>
                  <Typography className="companyInfoTitle3">
                    {" "}
                    {this.state.userInspectionData?.phone}
                  </Typography>
                </div>
              </div>
            </div>
  

            <div className="CardBoxParent">
              <Typography
                className="companyInfoTitle2"
                style={{
                  fontWeight: "bold",
                  fontSize: "26px",
                  marginBottom: "8px",
                }}
              >
                ID Card
              </Typography>
  
              <div className="CardBox">
                <div className="CardBoxWith">
                  <Typography className="companyInfoTitle4">
                    First Name:{" "}
                  </Typography>
                  <Typography className="companyInfoTitle3">
                    {this.state.userInspectionData?.info?.idDocs[0].firstName}
                  </Typography>
                </div>
  
                <div className="CardBoxWith">
                  <Typography className="companyInfoTitle4">
                    Full Name Transliteration:{" "}
                  </Typography>
                  <Typography className="companyInfoTitle3">
                    {this.state.userInspectionData?.info?.idDocs[0]?.firstNameEn}
                  </Typography>
                </div>
  
                <div className="CardBoxWith">
                  <Typography className="companyInfoTitle4">
                    Last Name:{" "}
                  </Typography>
                  <Typography className="companyInfoTitle3">
                    {this.state.userInspectionData?.info?.idDocs[0].lastName}
                  </Typography>
                </div>
  
                <div className="CardBoxWith">
                  <Typography className="companyInfoTitle4">
                    Last Name Transliteration:{" "}
                  </Typography>
                  <Typography className="companyInfoTitle3">
                    {this.state.userInspectionData?.info?.idDocs[0].lastNameEn}
                  </Typography>
                </div>
  
                <div className="CardBoxWith">
                  <Typography className="companyInfoTitle4">Number: </Typography>
                  <Typography className="companyInfoTitle3">
                    {this.state.userInspectionData?.info?.idDocs[0].number}
                  </Typography>
                </div>
  
                <div className="CardBoxWith">
                  <Typography className="companyInfoTitle4">
                    Date of Birth:{" "}
                  </Typography>
                  <Typography className="companyInfoTitle3">
                    {this.state.userInspectionData?.info?.idDocs[0].dob}
                  </Typography>
                </div>
  
                <div className="CardBoxWith">
                  <Typography className="companyInfoTitle4">
                    Expiry Date:{" "}
                  </Typography>
                  <Typography className="companyInfoTitle3">
                    {this.state.userInspectionData?.info?.idDocs[0].validUntil}
                  </Typography>
                </div>
              </div>
            </div>
          </div>
        </MainContent>
      </div>
    );
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      //Merge Engine DefaultContainer
      <>
      {
        <Headers sliderButton={this.state.sideBarClose}
        logOutToogle={this.logOutBtn} 
        data-testId="logoutTestId"
        onToggleClick={this.handleSliderButton}
        goToScreen = {this.goToScreen}
        isRouteData="PeopleManagement"
        >
          {  
          this.state.checkforview ?
          this.renderPerticulerDetails2():
          this.renderDashboardForm()
          }
      </Headers> 
      }
    </>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const Wrapper = styled(Box)({
  background: "#242424",
  padding: "12px 24px",
  height: 'calc(100vh - 87px)',
  boxSizing: "border-box",
  
  "& .ForwardRef(Box)-root-25 MuiBox-root css-0":{
      overflowY:'auto'
  },


  "& .home": {
    fontWeight: 600,
    color: "rgba(252,252,252,1)",
    fontSize: "40px",
    lineHeight: "48px",
    letterSpacing: "-2%",
    fontFamily: "Lato",
  },
  "& .headerBox": {
    marginTop: "18px",
    color: "rgba(252,252,252,1)",
    display: "flex",
    justifyContent: "space-between"
  },
  "& .itemBox": {
    background: "rgba(26,29,31,1)",
    padding: "24px 19px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap:"15px"
  },
  "& .itemBoxText": {
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "24px",
    fontFamily: "Lato",
    latterSpacing: "-1%",
  },
  "& .lastBox": {
    width: "240px",
  },
  "& .loadMoreBtnBox": {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "16px"
  },
  "& .loadMoreBtn": {
    textTransform: "none",
    color: "white",
    height: "48px",
    border: "2px solid rgba(39, 43, 48, 1)",
    fontSize: "15px",
    fontWeight: 700,
    borderRadius: "12px",
    fontFamily: 'Lato',
  },
  "& .itemBoxCount": {
    fontWeight: 500,
    fontSize: "30px",
    fontFamily: 'Lato',
  },
  "& .itemBoxIcon": {},
  "& .contentBox": {
    color: "rgba(252,252,252,1)",
    padding: "24px 0px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  "& .tableHeader": {
    fontFamily: "Lato",
    fontWeight: 600,
    fontSize: "24px",
    lineHeight: "40px",
    latterSpacing: "-2%",
  },
  dialogFirstInputField: {
    width: "100%", borderRadius: '8px',
    fontWeight: 600,
    border: '0.5px solid #F1F5F9',
    background: '#F1F5F9',
  },
  "& .datePicker": {
    border: "2px solid #808080",
    width: "180px",
    height: "44px",
    padding: "10px 18px",
    color: "#808080",
  },
  "& .calnderStyle": {
    border: "2px solid rgba(80, 80, 80, 1)",
    width: "180px",
    height: "42px",
    color: "rgba(80, 80, 80, 1)",
    marginTop: "0px",
    paddingBottom: "10px"
  },
  "& .placeholder-white::placeholder": {
    color: "white",
    "& .MuiInputBase-input":
    {
      paddingLeft: "36px"
    }
  },

  "@media (max-width: 660px)" :{

    "& .contentBox": {
        flexDirection:'column',
        display:'flex'
  },

  },


});
const MainContent = styled(Box)({  
  margin: "0 auto",
  padding: "20px",
  backgroundColor: "rgba(26, 29, 31, 1)",
  fontFamily: 'Lato',
  position: "relative",   
  "& .paginationButtons":{
      display: "flex",
      justifyContent: "center",
      marginTop:"10px",
      "& .MuiPagination-ul li":{
          backgroundColor: "grey"
      }
  },
  "& .loaderWrapper":{
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      color: "#fff"
  },
  "& .headerText": {
      display: "flex",
      color: "rgba(252, 252, 252, 1)",
      fontSize: "40px",
      fontWeight: 600,
      fontFamily: 'Lato',
      width: "100%",
      maxWidth: "450px",
      height: "auto",
      marginBottom: "20px",
      lineHeight: "1.2",
      textAlign: "left",
  },
  "@media (max-width: 768px)": {
      "& .headerText": {
          fontSize: "30px",
          maxWidth: "100%",
          textAlign: "center",
      },

  },
  "@media (max-width: 1200px)": {
      "& .headerText": {
          fontSize: "30px",
          maxWidth: "100%",
          textAlign: "center",
      },

  },
  "@media (max-width: 480px)": {
      "& .headerText": {
          fontSize: "24px",
          textAlign: "center",
          marginBottom: "15px",
      },
  },
  "& .companyInfoTitle": {
      fontFamily: 'Lato',
      fontSize: '32px',
      fontWeight: 600,   
      textTransform: 'none',
      color:"#F8FAFC"
    },
    "& .companyInfoTitle3": {
      fontFamily: 'Lato',
      fontSize: '18px',
      fontWeight: 600,  
      textTransform: 'none',
      color:'#A7A7A7',
      wordWrap:"break-word"
    },
    "& .companyInfoTitle2": {
      fontFamily: 'Lato',
      fontSize: '21px',
      fontWeight: 600,  
      textTransform: 'none',
      color:"#F8FAFC"
    },
    "& .CardBoxParent": {
      display: "flex",
      flexDirection: "column",
      padding: "35px",               
      color: "#fff",  
      borderBottom:"1px solid green",
      flexWrap:"wrap",  
    },
    "& .CardBox": {
      padding:"10px",
      backgroundColor:"#18191b",
      display:"flex", 
      flexWrap:"wrap",    
      gap:30
    },
    "& .CardBoxWith": {
        width:'22%',          
    },
    "@media (max-width: 1000px)": {
      "& .CardBoxWith": {
        width:'32%',          
    },
  },
    "& .companyInfoTitle4": {
      fontFamily: 'Lato',
      fontSize: '18px',
      fontWeight: 600,  
      textTransform: 'none',
      color:"#F8FAFC",
    },
    "& .backBtn": {
      height: "40px",
      width: "40px",
      color: "white"
    },
})
const customStyles = {
  noDataFoundDiv: {
    textAlign: 'center'
  } as React.CSSProperties,
  noDataFoundBox: {
    display: "flex",
    justifyContent: "center",
    margin: "30px 0px",
  },
  noDataFoundText: {
    fontSize: "16px",
    fontWeight: 500,
    fontFamily: "Open Sans",
    color: "#390689"
  },
}

const StyledTableContainer = styled(TableContainer)({
  width: "Fixed (1,264px)px",
  height: "Fixed (41px)px",
  gap: "24px",
  opacity: "0px",

  "& .userImg": {
    width: "48px",
    height: "48px",
    borderRadius: "48px"
  }
});

const TableDocWrapper = styled(Box)({
  "& .singleDoc": {
    display: "flex",
    alignItems: "center",
    gap: "8px",
  },
  "& .downloadBtn": {
    width: "16px",
    height: "16px",
    cursor:"pointer"
  }
});

const StyledTable = styled(Table)({
  backgroundColor: "#18191b",
  "& .MuiTableCell-root": {
    borderBottom: "1px solid #272B30",
  },
  "& .MuiTableCell-head": {
    color: "#fff",
    width: "max-content",
  },
  "& .MuiTableCell-body": {
    color: "rgba(111, 118, 126, 1)",
    fontSize: "14px",
    fontWeight: 600
  },
  "& .nameTxt": {
    color: "rgba(252, 252, 252, 1)",
    fontWeight: 700,
    fontFamily: 'Lato',
    height: "24px",
    width: "114px",
    fontSize: "15px",
    cursor:"pointer"
  },
  "& .dobTableTxt": {
    fontWeight: 600,
    color: "rgba(111, 118, 126, 1)",
    fontSize: "14px",
    fontFamily: 'Lato',
    height: "81px",
    width: "24px"
  },
  "& .countryTableText": {
    color: "rgba(111, 118, 126, 1)",
    fontSize: "14px",
    fontWeight: 600,
    width: "84px",
    height: "24px",
    fontFamily: 'Lato',
    whiteSpace: "nowrap"
  },
  "& .passportTextTable": {
    width: "81px",
    height: "24px",
    color: "rgba(111, 118, 126, 1)",
    fontSize: "14px",
    fontFamily: 'Lato',
    fontWeight: 600,
  },
  "& .nationalityText": {
    width: "80px",
    color: "rgba(111, 118, 126, 1)",
    fontSize: "14px",
    fontWeight: 600,
    fontFamily: 'Lato',
    height: "24px"
  },
  "& .addressText": {
    color: "rgba(111, 118, 126, 1)",
    fontSize: "14px",
    fontWeight: 600,
    fontFamily: 'Lato',
    width: "249px",
    height: "48px"
  },
  "& .nameTableCell": {
    width: "36px",
    fontFamily: 'Lato',
    color: 'rgba(211, 211, 211, 1)',
    height: '16px',
    gap: '0px',
    opacity: "0px",
    fontWeight: 600
  },
  "& .lato": {
    fontFamily: 'Lato'
  },
  "& .dobTableCell": {
    width: "78px",
    fontFamily: 'Lato',
    color: 'rgba(211, 211, 211, 1)',
    height: '16px',
    gap: '0px',
    opacity: "0px",
    fontWeight: 600,
    whiteSpace: "nowrap"

  },
  "& .nationalityTableCell": {
    color: 'rgba(211, 211, 211, 1)',
    width: "68px",
    fontFamily: 'Lato',
    height: '16px',
    gap: '0px',
    opacity: "0px",
    fontWeight: 600
  },
  "& .addressTableCell": {
    width: "50px",
    color: 'rgba(211, 211, 211, 1)',
    fontFamily: 'Lato',
    height: '16px',
    gap: '0px',
    opacity: "0px",
    fontWeight: 600
  },
  "& .passportTableCell": {
    width: "106px",
    fontFamily: 'Lato',
    fontWeight: 600,
    color: 'rgba(211, 211, 211, 1)',
    height: '16px',
    gap: '0px',
    opacity: "0px",
  },
  "& .documentTableCell": {
    gap: '0px',
    width: "70px",
    fontFamily: 'Lato',
    color: 'rgba(211, 211, 211, 1)',
    height: '16px',
    opacity: "0px",
    fontWeight: 600
  },
  "& .statusTableCell": {
    width: "70px",
    fontFamily: 'Lato',
    color: 'rgba(211, 211, 211, 1)',
    fontWeight: 600,
    height: '16px',
    gap: '0px',
    opacity: "0px",
  },
  "& .countryTableCell": {
    fontFamily: 'Lato',
    color: 'rgba(211, 211, 211, 1)',
    height: '16px',
    width: "50px",
    gap: '0px',
    opacity: "0px",
    fontWeight: 600
  },
  "& .documentNameText": {
    fontFamily: 'Lato',
    fontWeight: 600,
    color: "rgba(111, 118, 126, 1)"
  }
});

const UserName = styled(Box)({
  display: "flex",
  alignItems: "center",
  gap: "12px"
});

const StatusWrapper = styled(Box)({
  display: "flex",
  alignItems: "center",
  gap: "8px",
  
  "& .rejectedBtn": {
    backgroundColor: 'red',
    color: 'rgba(36, 36, 36, 1)',
    width: "98px",
    height: "28px",
    textTransform: "none",
    borderRadius: "0px",
    fontFamily: "Lato",
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "24px"
  },
  "& .approvedBtn": {
    backgroundColor: '#41d613',
    color: 'rgba(36, 36, 36, 1)',
    width: "76px",
    height: "28px",
    textTransform: "none",
    borderRadius: "0px",
    fontFamily: "Lato",
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "24px"
  },
  "& .downloadBtn": {
    width: "16px",
    height: "16px"
  },
  "& .downloadLargeBtn": {
    width: "24px",
    height: "24px"
  },
  "& .pendingBtn": {
    backgroundColor: 'yellow',
    color: 'rgba(36, 36, 36, 1)',
    width: "80px",
    height: "28px",
    extTransform: "none",
    marginLeft: "33px",
    textTransform: "none",
    borderRadius: "0px",
    fontFamily: "Lato",
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "24px"
  }, 
  "& .needClarificationBtn": {
    backgroundColor: "#FF7A01",
    color: "rgba(36, 36, 36, 1)",
    width: "200px",
    height: "36px",
    textTransform: "none",
    borderRadius: "0px",
    fontFamily: "Lato",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "24px"
  },
 
})

const PagginationBox = styled(Box)({
  marginLeft: "auto",
  marginTop: "20px",
  marginBottom:"35px",
  width: "max-content",
  "& .MuiPaginationItem-page.Mui-selected": {
    border: "1px solid grey !important"
  },
  "& .MuiPagination-ul .MuiButtonBase-root::before": {
    content: "",
  },
  "& .MuiPagination-ul li:first-child .MuiButtonBase-root::before": {
      backgroundColor: "transparent !important",
  },

  "& .MuiPaginationItem-page.Mui-selected::before": {
    backgroundColor: "transparent !important",
  },
  "& .MuiPagination-ul li:last-child .MuiButtonBase-root::before": {
      backgroundColor: "transparent !important",
  },
  "& .showingTxt": {
      fontFamily: "Plus Jakarta Sans",
      fontSize: "14px",
      color: "#212121"
  },
  "& .MuiSvgIcon-root": {
      fill: "grey"
  },

  "& .MuiPaginationItem-page": {
      backgroundColor: "unset",
      color: "rgba(0, 255, 0, 1)",
      borderBottom: "unset !important",
  },
  "& .MuiPagination-ul .MuiButtonBase-root": {
      position: "relative",
      borderRadius: "50%",
      border: "none"
  },
  "@media (max-width: 768px)": {
      justifyContent: "center",
      margin: "20px auto",
  },
});
const PageTop = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  height: "44px",
  paddingBottom: "20px",
  "& .searchInputfield": {
      backgroundColor: "#505050",
      color: "#fff",
      height: "44px",
      fontSize: "16px",
      fontWeight: 400,
      fontFamily: "Lato",
      width: "405px",
      outline: "none",
      border: "none",
      borderRadius: "0",
      padding: "10px",

      "& .MuiInputBase-input": {
          width: "100%",
          height: "24px",
          fontFamily: "Lato",
      },
  },

  "& .MuiOutlinedInput-inputAdornedStart": {
      color: "#A7A7A7",
      height: "7px",
  },
  "& .searchContainer": {
    display: "flex",
    justifyContent: "flex-start",
    gap: "15px",
    outline: "none",
    alignItems: "center",
    border: "none",
    borderRadius: "0px",
    
    "& .searchInputContainer": {
        position: "relative",
        
        "& .searchStatusContainer": {
            position: "absolute",
            padding: "16px",
            display: "flex",
            justifyContent: "space-between",
            gap: "10px",
            backgroundColor: "rgba(36, 36, 36, 1)",
            width: "100%",
            listStyle: "none",
            margin: 0,
            height: "10px",

            "& .searchStatus": {
                padding: "12px",
                fontSize: "8px",
                fontWeight: 600,
                lineHeight: "20px",
                fontFamily: "lato",
                textTransform: "capitalize",
                cursor: "pointer",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            },

            "& .searchApprovedStatus": {
                border: "1px solid rgba(0, 255, 0, 1)",
                color: "rgba(0, 255, 0, 1)",
            },
            "& .searchPendingStatus": {
                border: "1px solid rgba(251, 212, 0, 1)",
                color: "rgba(251, 212, 0, 1)",
            },
            "& .searchRejectedStatus": {
                border: "1px solid rgba(255, 25, 56, 1)",
                color: "rgba(255, 25, 56, 1)",
            },
            "& .searchClarificationStatus": {
                border: "1px solid rgba(255, 122, 1, 1)",
                color: "rgba(255, 122, 1, 1)",
            },
        },
    },
},

  "& .searchIcon": {
      color: "#A7A7A7",
      marginRight: "20px",
      width: "17.05px",
      height: "17.06px",
      cursor: "pointer",
  },

  "& .crossIcon": {
      color: "#A7A7A7",
      marginRight: "20px",
      width: "18.05px",
      height: "18.06px",
      cursor: "pointer",
  },

  "& .searchBtn": {
      height: "44px",
      width: "181px",
      backgroundColor: "#00FF00",
      color: "#242424",
      borderRadius: "0px",
      textTransform: "none",
  },

  "& .pageMain": {
      height: "44px",
      width: "1333px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
  },

  "@media (max-width: 1024px)": {
      "& .searchInputfield": {
          width: "405px",
      },
      "& .pageMain": {
          width: "100%",
          padding: "0 20px",
      },
      "& .searchBtn": {
          width: "150px",
      },
  },
  "@media (max-width: 768px)": {
      flexDirection: "column",
      "& .pageMain": {
          flexDirection: "column",
          alignItems: "flex-start",
      },
      "& .searchContainer": {
          flexDirection: "column",
          alignItems: "flex-start",
      },
      "& .searchInputfield": {
          width: "405px",
      },
      "& .searchBtn": {
          width: "100%",
      },
  },

  "@media (max-width: 520px)":{
    "& .searchInputfield" :{
      width:'100%'
    }
  },

  "@media (max-width:440px) and (min-width:380px)":{
    "& .searchInputfield" :{
      width:'270px'
    }
  },

  "@media (max-width:380px)":{
    "& .searchInputfield" :{
      width:'250px'
    }
  },


  "@media (max-width: 480px)": {
      "& .searchInputfield": {
          height: "36px",
          fontSize: "14px",
      },
      "& .searchBtn": {
          width: "100%",
          fontSize: "14px",
      },
  },
});
// Customizable Area End
