import React from "react";
// Customizable Area Start
import Headers from "../../../components/src/Headers.web";
import { Box, Grid, Typography, Button } from "@mui/material";
import { arrowIconBack, editIcon } from "./assets";
const profileImg = require("../assets/blankProfile.jpeg");
import PhoneInput from "react-phone-input-2";
import UserProfileBasicController, {
  Props,
  configJSON,
} from "./UserProfileBasicController.web";
import EmployeHeader from "../../../components/src/EmployeHeader.web";
import SuccessDialog from "../../../components/src/SuccessDailogox.web";
import { UserProfileBasicStyle } from "./UserProfileEdit.web";
import CircularProgress from '@material-ui/core/CircularProgress';
// Customizable Area End

export default class UserProfileEmployeeEdit extends UserProfileBasicController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  renderUserProfileImage = () => {
    const { selectedImage, userProfilePerticulerEmployee } = this.state;

    const imageSrc = selectedImage
      ? URL.createObjectURL(selectedImage)
      : userProfilePerticulerEmployee?.image || profileImg;

    const { imgSizeExceed, invalidFileType } = this.state;
    const borderColor =
      imgSizeExceed || invalidFileType ? "3px solid red" : "3px solid #00FF00";

    return (
      <>
        <Box
          className="userImage"
          data-testId="avatarBoxTestEmpId"
          style={{ border: borderColor }}
        >
          <img src={imageSrc} alt="Profile" className="userProfileImage" />
          <input
            onChange={this.handleAvatarFileChange}
            data-testId="setUserProfileTestId"
            ref={this.fileInputRef}
            type="file"
            className="imgUploadInput1"
            hidden
          />
        </Box>
      </>
    );
  };

  renderUsersProfile1 = () => {
    return (
      <div style={{height:"100%"}}>
      <UserProfileBasicStyle  style={{overflowY:"auto", height: "calc(100% - 71px)",boxSizing:"border-box"}}>
        <div className="root1">
          <Grid spacing={4} style={{ padding: "20px 10px" }}>
            <Grid item xs={11} className="profileHeader">
              <div>
                <div style={{ display: "flex" }}>
                  <img
                    src={arrowIconBack}
                    alt="ArrowIcon"
                    style={{ cursor: "pointer", marginRight: "5px" }}
                    data-testId="btnback1"
                    onClick={() => this.goToScreen("UserProfileBasicWeb")}
                  />
                  <Typography className="ProfileName">Profile</Typography>
                </div>
                <Typography
                  style={{ margin: "15px 0" }}
                  className="profileName2"
                >
                  Contact Details
                </Typography>
              </div>
            </Grid>
            <Grid item xs={11} className="statsContainar">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "20px",
                  flexWrap:"wrap"
                }}
              >
                <div>
                  {this.renderUserProfileImage()}
                  <Typography
                    className="uploadTextThree"
                    style={{
                      color:
                        this.state.imgSizeExceed || this.state.invalidFileType
                          ? "red"
                          : "inherit",
                    }}
                  >
                    {this.state.imgSizeExceed || this.state.invalidFileType
                      ? configJSON.uploadTextErr
                      : configJSON.uploadText}
                  </Typography>
                </div>
                <div style={{ display: "flex", alignItems: "end" }}>
                  <Button
                    className="button"
                    onClick={this.onChooseFile}
                    style={{
                      marginLeft: "8px",
                      backgroundColor:
                        this.state.imgSizeExceed || this.state.invalidFileType
                          ? "red"
                          : "#00ff00",
                    }}
                    variant="contained"
                  >
                    <img
                      src={editIcon}
                      alt="Arrow Icon"
                      style={{ marginRight: "5px", cursor: "pointer" }}
                      data-testId="btnback"
                    />
                    Change Photo
                  </Button>
                </div>
              </div>
            </Grid>

            <Grid
              item
              xs={11}            
              className="inputContainarParent"
            >
              <Box className="inputContainar" >
                <Typography className="LableName">Company ID*</Typography>
                <input
                  autoComplete="off"
                  className={"inputStyle"}
                  type="text"
                  placeholder="Enter Company ID"
                  data-testId="CompanyID"
                  name="personName"
                  value={
                    this.state.userProfilePerticulerEmployee.companyUniqueId
                  }
                  disabled={true}
                  style={{ fontWeight: "bold", color: "#A7A7A7" }}
                />
              </Box>

              <Box className="inputContainar" >
                <Typography className="LableName">Employee Name*</Typography>
                <input
                  autoComplete="off"
                  className={`${
                    this.state.userProfilePerticulerEmployee?.employeeName &&
                    this.state.userProfilePerticulerEmployee.employeeName.length > 0 &&
                    this.state.userProfilePerticulerEmployee.employeeName.length < 52
                      ? "inputStyle"
                      : "inputStyle errorStyle"
                  }`}
                  type="text"
                  value={this.state.userProfilePerticulerEmployee.employeeName}
                  name="employeeName"
                  data-test-id="employeeNameIdtest"
                  onChange={this.handleEmployeeData}
                  placeholder="Enter Employee Name"
                />
                {this.state.isLoaded && (
                  this.state.userProfilePerticulerEmployee.employeeName.length < 1 ||
                  this.state.userProfilePerticulerEmployee.employeeName.length > 51 ? (
                    <Typography className="errorMessage">
                      {configJSON.companyNameError}
                    </Typography>
                  ) : null
                )}
              </Box>
            </Grid>

            <Grid
              item
              xs={11}
              className="inputContainarParent"
            >
              <Box className="inputContainar" >
                <Typography className="lableName">Employee Email*</Typography>
                <input
                  className={"inputStyle"}
                  type="text"
                  autoComplete="off"
                  placeholder="Enter Email Id"
                  name="email"
                  data-testId="emailInput"
                  value={this.state.userProfilePerticulerEmployee.email}
                  disabled={true}
                  style={{ fontWeight: "bold", color: "#A7A7A7" }}
                />
              </Box>

              <Box className="inputContainar" >
                <Typography className="LableName">Phone Number*</Typography>
                <Box className="phoneNumberInputFiled">
                  <PhoneInput
                    data-testId="phoneInput"
                    placeholder="+41 Enter Phone Number"
                    enableSearch={true}
                    value={String(
                      this.state.userProfilePerticulerEmployee.fullPhoneNumber
                    )}
                    onChange={this.dailcodeData2}
                    inputProps={{
                      id: "PhoneNumber",
                      required: true,
                    }}
                    disableSearchIcon={true}
                    enableLongNumbers={true}
                    jumpCursorToEnd={true}
                    autoFormat={true}
                    inputStyle={{
                      backgroundColor: "#242424",
                      width: "100%",
                      border: "none",
                      height: "inherit",
                      color: "#A7A7A7",
                    }}
                    dropdownStyle={{
                      border: "none",
                      color: "#A7A7A7",
                      background: "none",
                    }}
                    containerStyle={{
                      backgroundColor: "#242424",
                      width: "98%",
                      height: "46px",
                      paddingLeft: "2%",
                      color: "#A7A7A7",
                      border: this.state.phoneNumberStatus
                        ? "1px solid red"
                        : "1px solid #FFFFFF",
                    }}
                  />
                </Box>
                <Typography className="errorMessage">
                  {this.state.phoneNumberStatus}
                </Typography>
              </Box>
            </Grid>

            <Grid
              item
              xs={11}
              className="inputContainarBTNParent">
            
              <Box className="inputContainarBTN">
                <Button
                  variant="contained"
                  className={this.state.btnStatus ? "disabledButton buttonBT" : "button buttonBT"}
                  style={{color: this.state.btnStatus ? "#00FF00" : "black"}} 
                  data-testId="UpdateTestBTN"
                  disabled={this.state.btnStatus}
                  onClick={this.UpdateUserProfilePerticulerEmployeeFunction}
                >
                   {this.state.Loader3 ? <CircularProgress thickness={4} size={30} /> : "Save Changes"}
                </Button>
              </Box>

              <Box className="inputContainarBTN">
                <Button
                  variant="outlined"
                  className="buttonChangePassword"
                  data-testId="btnTest"
                  onClick={() => this.goToScreen("UserProfileBasicWeb")}
                >
                  Cancel
                </Button>
              </Box>
            </Grid>
          </Grid>
        </div>
        <SuccessDialog
          disc={"Profile information successfully saved"}
          onClose={this.handleCancel}
          open={this.state.editSuccessDialogOpen}
        />
      </UserProfileBasicStyle>
      </div>
    );
  };
  // Customizable Area End
  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        {this.state.role === "company" ? (
          <Headers
            isRouteData={"UserProfileBasicWeb"}
            sliderButton={this.state.sideBarClose}
            logOutToogle={this.logOutBtn}
            data-testId="logoutTestId"
            onToggleClick={this.handleSliderButton}
            goToScreen={this.goToScreen}
          >
            {this.renderUsersProfile1()}
          </Headers>
        ) : (
          <EmployeHeader
            isRouteData={"UserProfileBasicWeb"}
            sliderButton={this.state.sideBarClose}
            logOutToogle={this.logOutBtn}
            data-testId="logoutTestId"
            onToggleClick={this.handleSliderButton}
            goToScreen={this.goToScreen}
          >
            {this.renderUsersProfile1()}
          </EmployeHeader>
        )}
      </>
      // Customizable Area End
    );
  }
}
// Customizable Area Start

// Customizable Area End
