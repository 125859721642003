import React from 'react'
import { Box, Dialog, DialogTitle, Grid, Typography, styled,Checkbox } from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';
interface DataProtectionDialogProps {

    open: boolean;
    disc: string;
    onClose: () => void;
    handleCheckboxClick2: () => void;
    checked2: boolean;
    logo: string;
    title:string;
}



const DataProtectionDiaglog: React.FC<DataProtectionDialogProps> = ({ open, onClose, disc, logo,title,checked2,handleCheckboxClick2 }) => {
    const handleIconClick = (event: React.MouseEvent) => {
        event.stopPropagation();
        onClose();
    };



    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <TermsConditionsStyles style={{ overflowX: "hidden", width: "100%", height: "100%", }}>
                <Box className="headerFiled">
                    <Box style={{ display: 'flex', color: 'black', padding: '5px 10px 0px 0px', justifyContent: 'flex-end', width: 'calc(100% - 3px)', height: '50px',cursor:"pointer" }}><CloseIcon onClick={handleIconClick} className="closeIcon" /></Box>
                    <Grid item xl={12} xs={12} lg={12} sm={12}>
                        <Box className="headerWrapper" style={{ display: 'flex', justifyContent: 'center' }}>
                            <Typography className="headerName">Data Protection Notice</Typography>
                        </Box>
                    </Grid>
                </Box>

                <Box style={{ background: '#242424' }}>
                    <Grid item xl={12} xs={12} lg={12} sm={12}>
                        <Box className="contentWrapper">

                            <Typography className="condTitle">
                                <h2>{disc}</h2>
                            </Typography>
                            <Typography className="termsContent" dangerouslySetInnerHTML={{ __html:title }}
                            >
                            </Typography>

                        </Box>
                        <Box style={{marginLeft:"58px",  paddingBottom:"10px"}}>                                     
                  <Checkbox                        
                      className="checkBox"                         
                      checked={checked2}
                      onClick={handleCheckboxClick2}                 
                   />
                    <label
                      style={{
                        color: '#D3D3D3',                       
                        fontFamily: 'Lato',                       
                        fontWeight: 400,
                        lineHeight: '22px',
                        fontSize: '14px',
                        cursor:"pointer",
                      }}
                      className="DataProtectionBottomBorder"  
                    >
                      I agree to the Data Protection Notice
                    </label>
                  </Box> 
                    </Grid>               
                </Box>
            
            </TermsConditionsStyles>
        </Dialog>
    )
}


const TermsConditionsStyles = styled(Box)({
    "& .headerFiled": {
        top: '80px',
        background: '#FFFFFF',
        gap: '10px',
        opacity: '0px',
        height: '140px',
    },
    "& .headerName": {
        fontWeight: 700,
        fontSize: '48px',
        lineheight: '56px',
        letterSpacing: '-0.015em',
        textAlign: 'left',
        color: '#00FF00',
        fontFamily: 'Lato',

    },
    "& .headerWrapper": {
        padding: '0px 112px',
        display: "flex",
        alignItems: 'center',
        gap: '20px'
    },
    "& .contentWrapper": {
        width: 'calc(100% - 120px)',
        gap: '40px',
        opacity: '0px',
        top: '296px',
        padding: '60px'
    },
    "& .condTitle": {
        textAlign: 'left',
        fontWeight: 600,
        lineHeight: '16px',
        color: '#D3D3D3',
        fontFamily: 'Lato',
        paddingBottom: '2px'
    },
    "& .termsContent": {
        lineHeight: '16px',
        fontWeight: 500,
        textAlign: 'justify',
        color: '#7C7C7C',
        fontSize: ' 16px',
        fontFamily: 'Lato',
        paddingBottom: '2px',


    },
    "& .checkBox": {
        cursor: "pointer",
        width: "20px",
        paddingLeft: "0px",
        marginRight: '8px',
        height: "20px",
        color: '#D3D3D3',
        '& .MuiSvgIcon-root': {
            fontSize: '20px',
        },
        '&.Mui-checked': {
            color: '#00FF00 !important',
        },
    },
    '& .customBtn': {
        minWidth: '116px',
        height: '48px',
        marginTop: '16px',
    },
    '& .btntypo': {
        textTransform: 'none',
        fontFamily: 'Lato',
        padding: "12px 20.5px",
        fontWeight: 700,
        fontSize: '16px',
        lineHeight: '24px',
        color: '#242424',
    },

    "& .closeIcon": {
        cursor: 'pointer',
        width: '35px',
        height: '35px',
    },




});






export default DataProtectionDiaglog;