Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.getUserListApiContentType = "application/json";
exports.getUserListApiMethod = "GET";
exports.getUserListApiEndPoint = "/bx_block_custom_forms/custom_forms";
exports.getUserDataEndPoint = "bx_block_customfeatureintegrationwithkyctoolsumsub/kyc_checks";
exports.getEmployeeListEndPoint = "account_block/employee_list";
exports.getCompanyEmployeeListEndPoint = "account_block/company_employees"

exports.deleteEmployeeApiMethod = "DELETE";
exports.deleteEmployeeApiEndPoint = "account_block/delete_employee"

exports.addUserApiMethod = "POST";
exports.addUserApiEndPoint = "/bx_block_custom_forms/custom_forms";

exports.editUserApiMethod = "PUT";
exports.editUserApiEndPoint = "/bx_block_custom_forms/custom_forms";
exports.detailssOfUserEndPoint = "/bx_block_customfeatureintegrationwithkyctoolsumsub/kyc_checks/applicant_data";

exports.createFormAPiEndPoint = "bx_block_custom_forms/custom_forms";       
exports.uploadDocumentEndPoint = "bx_block_customfeatureintegrationwithkyctoolsumsub/kyc_checks";       
exports.getAllUserDataListEndPoint = "bx_block_customfeatureintegrationwithkyctoolsumsub/kyc_checks/check_list";       
exports.token="eyJhbGciOiJIUzUxMiJ9.eyJpZCI6OCwiZXhwIjoxNjg2MzE3NzU3LCJ0b2tlbl90eXBlIjoibG9naW4ifQ.qtajs3wt_9FozBlJjL8iOYodM4KuZ0dVrsQbMYmH9798DOv21rRFy8FMgMlDzKDaKg7oZryerKM8ihX1x59fog";
exports.formAPiMethod = "POST";
exports.updateFormAPiMethod="PUT";
exports.fetchFormDataMethod="GET";
exports.formApiContentType = "multipart/form-data";
exports.errorPasswordNotValid = "Not valid.";
exports.labelHeader = " Custom Form ";
exports.btnTxtSubmit = "Submit";
exports.labelOr = "OR";
exports.placeHolderFirstName = "First Name";
exports.placeHolderLastName = "Last Name";
exports.placeHolderPhoneNo = "Phone Number";
exports.placeHolderOrg = "Organization";
exports.placeHolderTeamName = "Team Name";
exports.placeHolderEmail = "Email";
exports.placeHolderAddress = "Address";
exports.placeHolderCountry = "Country";
exports.placeHolderState = "State";
exports.placeHolderCity="City";

exports.errorEmptyFields = "All fields are required.";
exports.errorEmailNotValid = "Email not valid.";
exports.errorTitle = "Error";
exports.labelTitle = "Submit";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";

exports.emailRegex=/[a-zA-Z0-9#$%&\*\+-/=\?\_`|~]*[a-zA-Z0-9#$%&\*\+/=\?\_`|~]@/;
exports.userName="User's Information";
exports.firstName= "First Name*";
exports.middleNames = "Middle Name";
exports.lastName = "Last Name*";
exports.birthDate = "Date of Birth*";
exports.nationalityName = "Nationality*";
exports.selectCountry = "Country*";
exports.adressFirst = "Address Line 1*";
exports.secondAdress = "Address Line 2";
exports.stateSelect = "State*";
exports.citySelect = "City*";
exports.zipCode = "Zip Code*";
exports.passportNumber = "Passport Number*";
exports.uploadPassport = "Passport Front";
exports.uploadPassport2 = "Passport Back";
exports.uploadText = "Upload JPEG, PNG & PDF up to 10MB";
exports.uploadTextTwo = "Upload JPEG & PNG up to 10MB";
exports.uploadDocument= "Upload document";
exports.dragText = "Drag and drop the files here";
exports.browserBtn = "Browse";
exports.documentType = "Enter Document Type";
exports.cancelBtn = "cancel";
exports.uploadBtn = "Upload";
exports.successMessage = "User document successfully uploaded";
exports.uploadButton = "Upload document";
exports.verifyText = "verify";
exports.dateOfBirth = "Date of birth*"
exports.userInformation = "User's Verification";
exports.verifyText = "Verify";
exports.nameText = "Name";
exports.dateOfBirth = "Date of Birth*";
exports.nationality = "Nationality";
exports.country = "Country";
exports.address = "Address";
exports.passportNumber = "Passport No";
exports.documents = "Documents";
exports.userText = "Status";
exports.approvedText = "Approved";
exports.pendingText = "Pending";
exports.rejectedText = "Rejected";
exports.clearificationText = "Need clarification";
exports.nationalityText = "Select nationality";
exports.countryText = "Country";
exports.stateText = "State";
exports.cityText = "City";
exports.loadText = "Load more";
exports.emailId = "Email ID";
exports.contactName = "Contact Name";
exports.phoneNumber = "Phone Number";
exports.action = "Action";
exports.employeeInformation = "Employee Information";
exports.createEmployee =  "+  Create Employee";
exports.popUpData = "Are you sure you want to delete this employee?";
exports.sucessMsg = "Selected Employee Deleted Successfully";
exports.nameRegex = /^[a-zA-Z]*$/;
exports.dataNotText = "Data Not Found"
exports.uploadFileTypes = ['image/jpeg', 'image/png'];
exports.uploadFileTypes2 = ['image/jpeg', 'image/png', 'application/pdf'];
exports.countries = {
  "AFG": "Afghanistan",
  "ALA": "Åland Islands",
  "ALB": "Albania",
  "DZA": "Algeria",
  "ASM": "American Samoa",
  "AND": "Andorra",
  "AGO": "Angola",
  "AIA": "Anguilla",
  "ATA": "Antarctica",
  "ATG": "Antigua and Barbuda",
  "ARG": "Argentina",
  "ARM": "Armenia",
  "ABW": "Aruba",
  "AUS": "Australia",
  "AUT": "Austria",
  "AZE": "Azerbaijan",
  "BHS": "Bahamas",
  "BHR": "Bahrain",
  "BGD": "Bangladesh",
  "BRB": "Barbados",
  "BLR": "Belarus",
  "BEL": "Belgium",
  "BLZ": "Belize",
  "BEN": "Benin",
  "BMU": "Bermuda",
  "BTN": "Bhutan",
  "BOL": "Bolivia, Plurinational State of",
  "BES": "Bonaire, Sint Eustatius and Saba",
  "BIH": "Bosnia and Herzegovina",
  "BWA": "Botswana",
  "BVT": "Bouvet Island",
  "BRA": "Brazil",
  "IOT": "British Indian Ocean Territory",
  "BRN": "Brunei Darussalam",
  "BGR": "Bulgaria",
  "BFA": "Burkina Faso",
  "BDI": "Burundi",
  "CPV": "Cabo Verde",
  "KHM": "Cambodia",
  "CMR": "Cameroon",
  "CAN": "Canada",
  "CYM": "Cayman Islands",
  "CAF": "Central African Republic",
  "TCD": "Chad",
  "CHL": "Chile",
  "CHN": "China",
  "CXR": "Christmas Island",
  "CCK": "Cocos (Keeling) Islands",
  "COL": "Colombia",
  "COM": "Comoros",
  "COG": "Congo",
  "COD": "Congo, Democratic Republic of the",
  "COK": "Cook Islands",
  "CRI": "Costa Rica",
  "CIV": "Côte d'Ivoire",
  "HRV": "Croatia",
  "CUB": "Cuba",
  "CUW": "Curaçao",
  "CYP": "Cyprus",
  "CZE": "Czechia",
  "DNK": "Denmark",
  "DJI": "Djibouti",
  "DMA": "Dominica",
  "DOM": "Dominican Republic",
  "ECU": "Ecuador",
  "EGY": "Egypt",
  "SLV": "El Salvador",
  "GNQ": "Equatorial Guinea",
  "ERI": "Eritrea",
  "EST": "Estonia",
  "SWZ": "Eswatini",
  "ETH": "Ethiopia",
  "FLK": "Falkland Islands (Malvinas)",
  "FRO": "Faroe Islands",
  "FJI": "Fiji",
  "FIN": "Finland",
  "FRA": "France",
  "GUF": "French Guiana",
  "PYF": "French Polynesia",
  "ATF": "French Southern Territories",
  "GAB": "Gabon",
  "GMB": "Gambia",
  "GEO": "Georgia",
  "DEU": "Germany",
  "GHA": "Ghana",
  "GIB": "Gibraltar",
  "GRC": "Greece",
  "GRL": "Greenland",
  "GRD": "Grenada",
  "GLP": "Guadeloupe",
  "GUM": "Guam",
  "GTM": "Guatemala",
  "GGY": "Guernsey",
  "GIN": "Guinea",
  "GNB": "Guinea-Bissau",
  "GUY": "Guyana",
  "HTI": "Haiti",
  "HMD": "Heard Island and McDonald Islands",
  "VAT": "Holy See",
  "HND": "Honduras",
  "HKG": "Hong Kong",
  "HUN": "Hungary",
  "ISL": "Iceland",
  "IND": "India",
  "IDN": "Indonesia",
  "IRN": "Iran, Islamic Republic of",
  "IRQ": "Iraq",
  "IRL": "Ireland",
  "IMN": "Isle of Man",
  "ISR": "Israel",
  "ITA": "Italy",
  "JAM": "Jamaica",
  "JPN": "Japan",
  "JEY": "Jersey",
  "JOR": "Jordan",
  "KAZ": "Kazakhstan",
  "KEN": "Kenya",
  "KIR": "Kiribati",
  "PRK": "Korea, Democratic People's Republic of",
  "KOR": "Korea, Republic of",
  "KWT": "Kuwait",
  "KGZ": "Kyrgyzstan",
  "LAO": "Lao People's Democratic Republic",
  "LVA": "Latvia",
  "LBN": "Lebanon",
  "LSO": "Lesotho",
  "LBR": "Liberia",
  "LBY": "Libya",
  "LIE": "Liechtenstein",
  "LTU": "Lithuania",
  "LUX": "Luxembourg",
  "MAC": "Macao",
  "MDG": "Madagascar",
  "MWI": "Malawi",
  "MYS": "Malaysia",
  "MDV": "Maldives",
  "MLI": "Mali",
  "MLT": "Malta",
  "MHL": "Marshall Islands",
  "MTQ": "Martinique",
  "MRT": "Mauritania",
  "MUS": "Mauritius",
  "MYT": "Mayotte",
  "MEX": "Mexico",
  "FSM": "Micronesia, Federated States of",
  "MDA": "Moldova, Republic of",
  "MCO": "Monaco",
  "MNG": "Mongolia",
  "MNE": "Montenegro",
  "MSR": "Montserrat",
  "MAR": "Morocco",
  "MOZ": "Mozambique",
  "MMR": "Myanmar",
  "NAM": "Namibia",
  "NRU": "Nauru",
  "NPL": "Nepal",
  "NLD": "Netherlands, Kingdom of the",
  "NCL": "New Caledonia",
  "NZL": "New Zealand",
  "NIC": "Nicaragua",
  "NER": "Niger",
  "NGA": "Nigeria",
  "NIU": "Niue",
  "NFK": "Norfolk Island",
  "MKD": "North Macedonia",
  "MNP": "Northern Mariana Islands",
  "NOR": "Norway",
  "OMN": "Oman",
  "PAK": "Pakistan",
  "PLW": "Palau",
  "PSE": "Palestine, State of",
  "PAN": "Panama",
  "PNG": "Papua New Guinea",
  "PRY": "Paraguay",
  "PER": "Peru",
  "PHL": "Philippines",
  "PCN": "Pitcairn",
  "POL": "Poland",
  "PRT": "Portugal",
  "PRI": "Puerto Rico",
  "QAT": "Qatar",
  "REU": "Réunion",
  "ROU": "Romania",
  "RUS": "Russian Federation",
  "RWA": "Rwanda",
  "BLM": "Saint Barthélemy",
  "SHN": "Saint Helena, Ascension and Tristan da Cunha",
  "KNA": "Saint Kitts and Nevis",
  "LCA": "Saint Lucia",
  "MAF": "Saint Martin (French part)",
  "SPM": "Saint Pierre and Miquelon",
  "VCT": "Saint Vincent and the Grenadines",
  "WSM": "Samoa",
  "SMR": "San Marino",
  "STP": "Sao Tome and Principe",
  "SAU": "Saudi Arabia",
  "SEN": "Senegal",
  "SRB": "Serbia",
  "SYC": "Seychelles",
  "SLE": "Sierra Leone",
  "SGP": "Singapore",
  "SXM": "Sint Maarten (Dutch part)",
  "SVK": "Slovakia",
  "SVN": "Slovenia",
  "SLB": "Solomon Islands",
  "SOM": "Somalia",
  "ZAF": "South Africa",
  "SGS": "South Georgia and the South Sandwich Islands",
  "SSD": "South Sudan",
  "ESP": "Spain",
  "LKA": "Sri Lanka",
  "SDN": "Sudan",
  "SUR": "Suriname",
  "SJM": "Svalbard and Jan Mayen",
  "SWE": "Sweden",
  "CHE": "Switzerland",
  "SYR": "Syrian Arab Republic",
  "TWN": "Taiwan, Province of China",
  "TJK": "Tajikistan",
  "TZA": "Tanzania, United Republic of",
  "THA": "Thailand",
  "TLS": "Timor-Leste",
  "TGO": "Togo",
  "TKL": "Tokelau",
  "TON": "Tonga",
  "TTO": "Trinidad and Tobago",
  "TUN": "Tunisia",
  "TUR": "Türkiye",
  "TKM": "Turkmenistan",
  "TCA": "Turks and Caicos Islands",
  "TUV": "Tuvalu",
  "UGA": "Uganda",
  "UKR": "Ukraine",
  "ARE": "United Arab Emirates",
  "GBR": "United Kingdom of Great Britain and Northern Ireland",
  "UMI": "United States Minor Outlying Islands",
  "USA": "United States of America",
  "URY": "Uruguay",
  "UZB": "Uzbekistan",
  "VUT": "Vanuatu",
  "VEN": "Venezuela, Bolivarian Republic of",
  "VNM": "Viet Nam",
  "VGB": "Virgin Islands (British)",
  "VIR": "Virgin Islands (U.S.)",
  "WLF": "Wallis and Futuna",
  "ESH": "Western Sahara",
  "YEM": "Yemen",
  "ZMB": "Zambia",
  "ZWE": "Zimbabwe"
}

// Customizable Area End