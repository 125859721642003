import React from "react";
// Customizable Area Start
import {  Box, Button, Grid, Link, Typography, styled } from '@material-ui/core';
import { logo, mainImage,blurImage,mainLogo,ArrowIcon}from "./assets";
import EmailAccountLoginController, { Props } from "./EmailAccountLoginController.web";
import CircularProgress from '@material-ui/core/CircularProgress';
import { LazyLoadImage } from "react-lazy-load-image-component";
import DataProtectionDiaglog from "../../../components/src/DataProtectionDiaglog";
import LazyLoadImageComponentEnterCode from "../../../components/src/LazyLoadImageComponent"
// Customizable Area End
export default class EnterCode extends EmailAccountLoginController {
    
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }  
   

    render() {
        return (
            <EnterCodeStyle>
                 <Box>
                    <Grid xl={12} xs={12} lg={12} sm={12}>
                        <Box 
                          style={{
                            backgroundColor: "#242424",
                            width: "100%",
                            borderBottom: "1px solid #505050",
                            display:'flex',
                            justifyContent:'flex-end',
                          }}
                        >
              <LazyLoadImage
               src={mainLogo}
               style={{ display: "flex",
               marginLeft: "auto",
               marginRight:'20px' ,
               paddingRight: "2%",
            }}
               alt="logo"
               placeholderSrc={logo}
               effect="blur"
               wrapperClassName="lazy-load-wrapper-logo"
               data-test-id="lazyloadimage"
                />
                        </Box>
                    </Grid>
                 </Box>
                <Box>
                    <Grid container>
                        <Grid item xl={7} lg={7} md={7} sm={12} xs={12} style={{ backgroundColor: '#242424' }} >
                            <Box className="main-container">                                   
                                <Box style={{ width: '55%', margin: 'auto' }}>                              
                                <img src={ArrowIcon} alt="Arrow Icon" style={{marginBottom: '15px' , cursor: "pointer"}} onClick={this.naviagateLogin} data-test-id="btn-back"/>
                                    <Box style={{ color: 'white' }}>
                                        <Typography className="createAccount" style={{cursor: "context-menu"}}>
                                        Check your Email
                                        </Typography>
                                        <Typography className="subheading" style={{cursor: "context-menu"}}>
                                        We have sent the verification OTP to {this.state.PersonEmail}
                                        </Typography>
                                    </Box>
                                    <Button onClick={this.handleNaviagate} className="customButton"  disabled={this.state.dataProtectionBox && !this.state.checked2} style={
                                  this.state.dataProtectionBox && !this.state.checked2  ? { backgroundColor: "#7C7C7C" }: { backgroundColor: "#00FF00" }  }>                                        
                                        {this.state.Loader ? <CircularProgress thickness={4} size={30} /> : "Enter a code"}
                                        </Button>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xl={5} lg={5} md={5} sm={12} xs={12} style={{background:'rgb(36, 36, 36)'}} >                         
                        <LazyLoadImageComponentEnterCode mainImage={mainImage} blurImage={blurImage}data-test-id="lazyEnterCode" />
                        <style>
  {
    `
    .lazy-load-wrapper-logo{
        width: "100%",
        borderBottom: "1px solid #505050",
        backgroundColor: "#242424",
    }
    `
}
</style>
                           </Grid>                       
                    </Grid>
         {this.state.dataProtectionBox && (
          <DataProtectionDiaglog
            open={this.state.dataProtectionBox}
            onClose={this.handleCloseDiaglog}
            disc={this.state.titleDataProtection}
            title={this.state.descriptionDataProtection}
            logo={logo}
            checked2={this.state.checked2}
            handleCheckboxClick2={this.handleCheckboxClick2}  
          />
         )}
                </Box>
            </EnterCodeStyle>
        );
    }
}
const EnterCodeStyle = styled(Box)({
    '& .MuiTab-wrapper': {
        flexDirection: 'row',
        justifyContent: 'space-evenly'
    },
    '& .tabsStyle .MuiTab-textColorPrimary.Mui-selected': {
        backgroundColor: '#00FF00',
    },
    '& .MuiInput-underline::after':{
        borderBottom:'none'
    },
    '& .tabsStyle': {
        display: 'flex',
        justifyContent: 'space-around',
    },
    '& .logoContainer':{
        backgroundColor: '#242424', 
        width: '100%', 
        borderBottom: '1px solid #505050'
    },
    '& .logoWrapper':{
        display: 'flex',
        marginLeft: 'auto',
        paddingRight: '2%'
    },
    '& .MuiTabs-flexContainer': {
        justifyContent: 'space-around'
    },
    '& .MuiIconButton-edgeEnd':{
        marginRight:'0px'
    },
    '& .PrivateTabIndicator-colorPrimary-10': {
        backgroundColor: 'none'
    },
    '& .MuiOutlinedInput-notchedOutline': {
        border: ' none ! important',
    },
    '& .MuiInputBase-input': {
        color: 'white'
    },
    '& .subheading': {       
        color: '#D3D3D3',
        fontFamily: 'Lato',
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '22px',
        textAlign: 'left'
    },
    '& .inputStyle': {
        width: '100%',
        height: '56px',
        backgroundColor: '#242424',
        color: '#A7A7A7',
        border: '1px solid #FFFFFF',
        paddingLeft: '2%'
    },
    '& .main-container': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%'
    },
    '& .lableName': {
        fontSize: '16px',
        fontWeight: 700,
        color: '#D3D3D3'
    },
    '& .inputCheck': {
        width: '20px',
        height: '20px',
        cursor: 'pointer',
        backgroundColor: 'none'
    },
    '& .inputStyle:focus': {
        outline: 'none'
    },
    '& .inputContainer': {
        display: 'flex',
        flexDirection: 'column',
        marginTop: '5%'
    },
    '& .createAccount': {
        fontSize: '30px',
        fontWeight: 700,
        color: '#D3D3D3',
        fontFamily: 'Lato', 
        lineHeight: '40px',
        letterSpacing: '-0.005em',
        textAlign: 'left'
    },
    '& .customButton': {
        width: '100%',
        height: '56px',
        textTransform: 'none',
        marginTop: '4%',
        backgroundColor: '#00FF00',
        fontWeight: 700,
        fontFamily: 'Lato',
        fontSize: '16px',       
        lineHeight: '24px',
        textAlign: 'left'
    },
    '& .phoneCodeStyle': {
        position: 'absolute',
        color: 'white',
        marginTop: '1%'
    },
    '& .MuiSvgIcon-root':
    {
        color: "white"
    },
    '& .css-1laao21-allyText': {
        width: '100%',
        height: '56px',
        backgroundColor: '#242424',
        color: '#A7A7A7',
        border: '1px solid #FFFFFF',
        paddingLeft: '2%'
    },
    '& .css-1pahdxg-control': {
        width: '100%',
        height: '56px',
        backgroundColor: '#242424',
        color: '#A7A7A7',
        border: '1px solid #FFFFFF',
        paddingLeft: '2%'
    },
    "& .cb1": {
        borderRadius: '50%',
        verticalAlign: 'middle',
        border: '1px solid pink',
        appearance: 'none',
        // -webkit-appearance: 'none',
        outline: 'none',
    },
    '& .css-yk16xz-control ': {
        width: '100%',
        height: '56px',
        backgroundColor: '#242424',
        color: '#A7A7A7',
        border: '1px solid #FFFFFF',
        paddingLeft: '2%'
    },
    "& .loadingContainer": {
        flexDirection: "column",
        justifyContent: "center",
        textAlign: "center",
        display: "flex",
        alignItems: "center",
      },
      "& .spinner": {
        width: "81px",
        borderTopColor: "#000",
        height: "81px",
        animation: "spin 1s ease-in-out infinite",
        border: "6px solid rgba(0, 0, 0, 0.1)",
        borderRadius: "50%",
      },
      "& .loading-text": {       
        fontSize: "19px",
        fontWeight: "bold",
        marginTop: "11px",
      },
})
