import React from 'react';

// Customizable Area Start
import {
  Container,
  Box,
  Button,
  IconButton,
  Grid,
  Typography,
  TextField,
} from '@mui/material';
import {
  createTheme,
} from '@mui/material/styles';
import {  ArrowBack } from '@mui/icons-material';
import { styled } from '@material-ui/styles';
import Headers from '../../../components/src/Headers.web';
import InviteEmployeeController, {
  Props
} from './InviteEmployeeController.web';
import PhoneInput from "react-phone-input-2";
const theme = createTheme({
  palette: {
    primary: {
      main: '#0000ff',
      contrastText: '#fff',
    },
  },
});
// Customizable Area End

export default class EmployeeEditScreen extends InviteEmployeeController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { Editdesignation, EditphoneNumber, EditemployeeEmail, EditemployeeName } = this.state;
    return (
        <Headers sliderButton={this.state.sideBarClose}
          onToggleClick={this.handleSliderButton}
          logOutToogle={this.logOutBtn} 
          data-testId="logoutTestId"
          goToScreen = {this.goToScreen}
          isRouteData="PeopleManagement"
        >
          <StyledContainer maxWidth="sm">
            <BoxContainer>
              <Grid className='eheaderBox' container alignItems="center" spacing={2} >
                <Grid style={{ padding: "0px" }} item>
                  <IconButton edge="start">
                    <ArrowBack  onClick={() => this.goToScreen("PeopleManagement")} data-testId="backButtonTest" style={{height: "40px",width: "40px", color: "white"}}/>
                  </IconButton>
                </Grid>
                <Grid style={{ padding: "0px 16px" }} item>
                  <Typography className='headerTxt' variant="h5">Edit Employee</Typography>
                </Grid>
              </Grid>
              <form noValidate autoComplete="off">
              <Grid container rowSpacing={1} columnSpacing={{xs:1, sm:2,md:3}} className='editinputsCotainer'>
                <Grid item xs={6} className='editinputs'>
                <label className="labelText">Employee Name <span style={{ color: 'red' }}>*</span></label>
                <TextField
                  fullWidth
                  margin="normal"
                  name="EditemployeeName"
                  placeholder="Employee Name"
                  value={EditemployeeName}
                  onChange={this.editHandleChange}
                  variant="outlined"
                  className="textName"
                  data-testId="EditemployeeNameTest"
                  error={Boolean(this.state.empNameError)}
                  helperText={this.state.empNameError}
                />
                </Grid>
                <Grid item xs={6} className='editinputs'> 
                <label className="labelText">Enter Designation</label>
                  <TextField
                    fullWidth
                    margin="normal"
                    name="Editdesignation"
                    placeholder="Enter Designation"
                    value={Editdesignation}
                    onChange={this.editHandleChange}
                    variant="outlined"
                    className="textName"
                    data-testId="EditdesignationTest"
                    error={Boolean(this.state.deginationError)}
                    helperText={this.state.deginationError}
                  />
                </Grid>
                <Grid item xs={6} className='editinputs'>
                <label className="labelText">Phone Number <span style={{ color: 'red' }}>*</span></label>
                <Box style={{ display: "flex" , paddingTop:"22px"}}>
                    <PhoneInput
                      data-test-id="phoneInput"
                      placeholder="+41 Enter Phone Number"
                      enableSearch={true}
                      inputProps={{
                        required: true,
                        id: "PhoneNumber",
                      }}
                      value={EditphoneNumber}
                      onChange={this.EditdailcodeData}
                      jumpCursorToEnd={true}
                      autoFormat={true}
                      inputStyle={{
                        width: "100%",
                        border: "none",
                        backgroundColor: "#242424",
                        color: "#A7A7A7",
                        height: "inherit",
                      }}  
                      enableLongNumbers={true}
                      disableSearchIcon={true}
                      containerStyle={{
                        width: "100%",
                        height: "46px",
                        backgroundColor: "#242424",
                        color: "#A7A7A7",
                        border: this.state.phoneNumberStatus
                          ? "1px solid red"
                          : "1px solid #FFFFFF",
                        paddingLeft: "2%",
                      }}
                      dropdownStyle={{
                        color: "#A7A7A7",
                        border: "none",
                        background: "none",
                      }}
                    />
                  </Box>
                  <Typography className="errorMessagee">
                    {this.state.phoneNumberStatus}
                  </Typography>
                </Grid>
                <Grid item xs={6} className='editinputs'>
                <label className="labelText">Employee Email <span style={{ color: 'red' }}>*</span></label>
                  <TextField
                  fullWidth
                    margin="normal"
                    name="EditemployeeEmail"
                    placeholder="Employee Email"
                    value={EditemployeeEmail}
                    onChange={this.editHandleChange}
                    variant="outlined"
                    className="textName"
                    data-testId="EditemployeeEmailTest"
                    error={Boolean(this.state.emailError)}
                    helperText={this.state.emailError}
                    disabled={true}  
                  />
                </Grid>
                </Grid>
                <Grid style={{marginTop:"24px"}} container spacing={2} className='editinputsCotainer'>
                  <Grid item>
                    <Button data-testId="cancelButtonTest" onClick={this.goToPeopleManagementScreen} className='btnIEmp' variant="contained">
                      <Typography>Cancel</Typography>
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button data-testId="formDataTestId" className='btnIEmp colorBtn' variant="contained" onClick={this.updateEmployee}>
                      <Typography>Update</Typography>
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </BoxContainer>
          </StyledContainer>
        </Headers>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const BoxContainer = styled(Box)({
  height:"496px",
  top:"120px",
  "& .labelText":{
    fontFamily: "Lato",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "24px",
    letterSpacing: "-0.01em",
    textAlign: "left",
    color:"#EFEFEF"
  },

  "& .headerTxt":{
    fontFamily:"Lato",
    fontSize: "40px",
    fontWeight: "600",
    lineHeight: '48px',
    letterSpacing: "-0.02em",
    textAlign: "left",
    color:"#FCFCFC"
  },

  "& .eheaderBox":{
    margin:"0px 0px 32px 0px",
  },
  "& .textName":{
    color:"white",
    '& .MuiOutlinedInput-root': {
      '& .MuiInputBase-input': {
        fontFamily: 'Lato, sans-serif',
        fontSize: "14px",
        fontWeight: 600,
        lineHeight: "24px",
        letterSpacing: "-0.01em",
        textAlign: "left",
        color: "#D3D3D3"
      },
      '&:hover fieldset': {
        borderColor: theme.palette.grey[600],
      },
      '& fieldset': {
        borderColor: theme.palette.grey[400],
      },
      '& .MuiInputBase-input::placeholder': {
        color: '#7C7C7C',
        opacity: 0.3,
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.grey[400],
      },
      '& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled': {
        opacity: 1,
        WebkitTextFillColor: "#D3D3D3",
      },
      "& .css-1x5jdmq.Mui-disabled":{
        opacity: 1,
        WebkitTextFillColor: "#D3D3D3",
      },
    },
  },
  "& .btnIEmp":{
    width:"162px",
    height:"48px",
    padding:"12px 20px",
    background:"transparent",
    border:"1px solid",
    borderRadius:"0px",
    textTransform: "capitalize",
    transition: "background 0.3s ease" 
  },
  "& .btnIEmp:hover": {
    background: "#2f2f2f"
  }, 
  "& .colorBtn":{
    background: "#00FF00",
    color:" black",
    fontFamily: "lato"
  },
  "& .colorBtn:hover": {
    background: "#00FF00"
  },
  "& .errorMessagee": {
    color: "red",
    fontSize: "12px",
    marginTop: "5px",
  },


  "& .react-tel-input .selected-flag": {
    width: "0px",
    padding: "0px",
    borderRadius: "0px",
  },

  "& .react-tel-input .flag-dropdown": {
    background: "none",
    border: "none",
  },

  "& .react-tel-input": {
    width: "30%",
    height: "46px",
  },

  "& .react-tel-input .country-list": {
    zIndex: 37,
    margin: "-250px 0 0px -10px",
    boxShadow: "1px 2px 10px rgba(0, 0, 0, .35)",
    width: "377px",
    borderRadius: "7px",
    backgroundColor: "#2f2f2f !important",
  },

  "& .react-tel-input .form-control": {
    width: "74%",
    paddingLeft: "28px",
    backgroundColor: "rgb(37 37 37) !important",
  },


  "& .css-sghohy-MuiButtonBase-root-MuiButton-root.Mui-disabled ":{
    color:"#FEFEFE"
  },
  "& .editinputs":{
    maxWidth:"100%"
  },
  "@media (max-width: 768px)": {
    border:"1px solid red",
    "& .editinputsCotainer": {  
      display: "flex",
      flexDirection:"column",
    },
    "& .btnIEmp":{
      width:"100%",
     
    }
  },
})

const StyledContainer = styled(Container)({
  height:"100vh",
  maxWidth:"100vw !important",
  backgroundColor: "#242424",
  paddingTop: "24px"
})
// Customizable Area End
