import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { toast } from "react-toastify";
export interface CountryCodeObj {
  name: string;
  dialCode: string;
  countryCode: string;
  format: string;
}
export interface LandingPageResponse{
  message: string;
}


interface PrivacyPolicyData {
  data: PrivacyPolicy;
}

interface PrivacyPolicy {
  id: string;
  type: string;
  attributes: Attributes;
}

interface Attributes {
  description: string;
  title: string;
}
interface Video {
  id: number;
  type: string;
  url: string;
  filename: string;
  visible: boolean;
}
interface Image {
  id: number;
  type: string;
  url: string;
  filename: string;
  visible: boolean;
}
interface VideoContent {
  video?: Video;
  image?: Image;
}
interface ContentAttributes {
  section:string;
  title: string;
  sub_title: string | null;
  description: string;
  sub_contents:string |null;
  video?: VideoContent | null; 
}

// Interface for each content
interface Content {
  id: string;
  type: string;
  attributes: ContentAttributes;
}

// Interface for the entire API response
interface ApiResponse {
  data: Content[];
  meta: {
    message: string;
  };
}



// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  employeeData: {
    dailNumber: string;
    PhoneNumberValidationCheck:number;
  };
  userName:string;
  userEmail:string;
  userMessage:string;
  phoneNumberStatus: string;
  isLoader:boolean;
  emailError:boolean;
  open:boolean;
  privacyData:PrivacyPolicy;
  landingpageData:ApiResponse;
  serviceTitle:string;
  titleOne:string;
  discripetionOne:string;
  titletwo:string;
  discripetiontwo:string;
  titlethree:string;
  discripetionthree:string;
  titlecontactus:string;
  subtitlecontactus:string|null;
  discripetioncontactus:string;
  videoPath:string|undefined;
  imageUrl:string|undefined;
  showSkeleton:boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class LandingPageController extends BlockComponent<
  Props,
  S,
  SS
> {
      // Customizable Area Start
      landingPageApiCallId: string = "";
      privacyDiaglogApiCallId:string="";
      landingDiscriptionApiCallId:string="";
    // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
       // Customizable Area Start
       getName(MessageEnum.RestAPIResponceMessage),
       // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      employeeData: {

        dailNumber: "",
        PhoneNumberValidationCheck: 100,
      },
      phoneNumberStatus: "",
      userName: "",
      userEmail: "",
      userMessage: "",
      isLoader: false,
      emailError:false,
      open:false,
      privacyData: {
        id: "", 
        type: "", 
        attributes: {
          title: "No Title", 
          description: "No Description", 
         
        },
      },
      landingpageData: {
        data: [
          {
            id: "", 
            type: "", 
            attributes: {
              section:"",
              title: "", 
              sub_title: "", 
              description: "",
              sub_contents: "",
            },
          },
        ],
        meta: {
          message: "", 
        },
      },
      titleOne:"",
      discripetionOne:"",
      titletwo:"",
      discripetiontwo:"",
      titlethree:"",
      discripetionthree:"",
      titlecontactus:"",
      subtitlecontactus:"",
      discripetioncontactus:"",
      videoPath:"",
      imageUrl:"",
      serviceTitle:"",
      showSkeleton:true
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
        const apiRequestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
        );
        let responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (apiRequestCallId === this.landingPageApiCallId) {
           if(responseJson && !responseJson.errors){
            this.landingPageSuccessCallback(responseJson);
           }
         }
         if(apiRequestCallId === this.privacyDiaglogApiCallId){
           if(responseJson && !responseJson.errors){
            this.privacyDiaglogSuccessCallback(responseJson);
           }
         }
         if(apiRequestCallId === this.landingDiscriptionApiCallId){
          this.setState({
            showSkeleton:false
          })
          if(responseJson && !responseJson.errors){
            this.landingDiscriptionReponse(responseJson);
           }
         }
        }
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
   super.componentDidMount();
   this.landingDiscription()

  }

  goToHome() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }
  landingPageSuccessCallback = async (responseJson: LandingPageResponse) => {
    if (responseJson && responseJson?.message) {
      this.setState({
        userName: "",
        userEmail: "",
        userMessage: "",
        employeeData: {
          dailNumber: "",
          PhoneNumberValidationCheck: 0
        },isLoader:false
      })
      await toast.success("Your message has been submitted successfully, we will get back to you in next 24 hours",  { hideProgressBar: true ,position: "top-center", style: { backgroundColor: '#003111', color: 'white' }} )
    }
  }

  privacyDiaglogSuccessCallback=async (responseJson:PrivacyPolicyData)=>{
   
  this.setState({privacyData:responseJson?.data})
    
  }
  
    handleOpenDiaglog=()=>{

      this.privacyDialogAPICall();

        this.setState({
          open:true
        })
  }

  handleCloseDiaglog=()=>{
    this.setState({
      open:false
    })
  }


  dailcodeData = async (value: string, data: CountryCodeObj, event: React.ChangeEvent<HTMLInputElement>, formattedValue: string) => {
    let dailCodeLenght= data?.dialCode?.length;
    let numberData = data?.format?.split('.').length - 1;
    let difference = numberData - dailCodeLenght;
    let displayDifference = isNaN(difference) ? "valid" : difference;
    let reference = this.state.employeeData
    reference.dailNumber = value    
    reference.PhoneNumberValidationCheck = numberData
   
    this.setState({
      employeeData: reference,
    }, () => { 
      if (this.state.employeeData.dailNumber.length !== this.state.employeeData.PhoneNumberValidationCheck) {
        this.setState({ phoneNumberStatus: `Please write ${displayDifference} digits`});
      } else {
        this.setState({ phoneNumberStatus: "" });
      }
    });
  }; 

  getCurrentYear = () => {
    return new Date().getFullYear();
  };

  landingPageAPI = async  () =>{
    this.setState({isLoader:true});
    const {
      dailNumber,
    } = this.state.employeeData;
    const { userName,
      userEmail,
      userMessage } = this.state;
      if(!dailNumber || !userName || !userEmail || !userMessage){
        toast.error("Please enter all details!",  { hideProgressBar: true ,position: "top-center", style: { backgroundColor: '#003111', color: 'white' }} )
        this.setState({isLoader:false});
      }else{
        this.landingPageApiCallId = await this.landingPageAPICall();
      }
  }

  landingPageAPICall = async () => {
    const {
      dailNumber,
    } = this.state.employeeData;
    const { userName,
      userEmail,
      userMessage } = this.state;
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
    };
    
    const httpBody = {
      data:
      {
        "name": userName,
        "email": userEmail,
        "phone_number": dailNumber,
        "details": userMessage
      }
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
 
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.landingPageEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;

  };



privacyDialogAPICall= async () => {

  const header = {
    "Content-Type": configJSON.exampleApiContentType,
  };
  
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.privacyDiaglogApiCallId= requestMessage.messageId;

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.privacyPageEndPoint
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.validationApiMethodType
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
   

}

landingDiscriptionReponse = (responseJson: ApiResponse) => {
  const updatedState: any = {};

  responseJson.data.forEach(content => {
    const { title, description, sub_title, video,section } = content.attributes;

    switch (section) {
      case "section_1":
        updatedState.titleOne = title;
        updatedState.discripetionOne = description;
        break;

      case "section_2":
        updatedState.titletwo = title;
        updatedState.discripetiontwo = description;
        break;

      case "section_3":
        updatedState.titlethree = title;
        updatedState.discripetionthree = description;
        break;

      case "section_5":
        updatedState.titlecontactus = title;
        updatedState.subtitlecontactus = sub_title;
        updatedState.discripetioncontactus = description;
        break;

        case "section_4":
          if (video && video.video) {
            updatedState.videoPath = video.video.url;
          }
          if (video && video.image) {
            updatedState.imageUrl = video.image.url;
          }
          break;

      default:
        break;
    }
  });

  this.setState(updatedState);
};


landingDiscription= async () => {

  const header = {
    "Content-Type": configJSON.exampleApiContentType,
  };
  
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.landingDiscriptionApiCallId= requestMessage.messageId;

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.landingPageEndPoint2
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.validationApiMethodType
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
}




  handleFirstName =(event:React.ChangeEvent<{value: string }> )=>{
    const firstName = event.target.value;
    this.setState({
            userName: firstName
    });
  };
  
  handleEmailChange = (event: React.ChangeEvent<{ value: string }>) => {
    const emailRegex = configJSON.emailRegex;
    const emailName = event.target.value;
    this.setState({
      userEmail: emailName,
    });
    if (!emailRegex.test(emailName)) {
      this.setState({ emailError: true });
    } else {
      this.setState({ emailError: false });
    }
  }

  handleMessageChange=(event: React.ChangeEvent<{value: string }>)=>{
    const mesageName = event.target.value;
    this.setState({
            userMessage: mesageName
    });
  };

  goToLoginScreen = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "EmailAccountLoginWeb");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };
  // Customizable Area End
}
