import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  Checkbox,
  Grid,
  Typography,
  styled,
} from "@material-ui/core";
import {arrowIconBack, logo,scaleKYC} from './asserts';
import StripePayments from "../../../components/src/StripeWrapper.web";
import SuccessDailogBoxProfile from '../../../components/src/SuccessDailogBoxProfile.web';
import FailDailogBox from '../../../components/src/FailDailogBox.web';
import DataProtectionDiaglog from "../../../components/src/DataProtectionDiaglog";
import DOMPurify from 'dompurify';
import Skeleton from '@material-ui/lab/Skeleton'
import { ReactElement } from "react";
// Customizable Area End

import TermsConditionsController, {
  Props,
  configJSON,
  ITermsConds,
} from "./TermsConditionsController";

export default class TermsConditions extends TermsConditionsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderLoader = (content?:ReactElement|string,style?:React.CSSProperties) => {
    return (this.state.showSkeleton?
    <Skeleton style={{background: ' rgb(113, 108, 108)', borderRadius: '6px', ...style}}  variant="text" />
    :
    <>{content}</>
  )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { role, checked } = this.state;
    const isCompany = role === "company";

    return (
      <TermsConditionsStyles 
      style={{ width: "100%", height: "100%",background:"rgb(36, 36, 36)" }}
      >
        <Box>
            <Grid item xl={12} xs={12} lg={12} sm={12}>
              <Box
                style={{
                  borderBottom: "1px solid #505050",
                  width: "100%",
                  backgroundColor: "#242424",             
                  
                }}
              >
                <img
                  alt="logo"
                  src={logo}
                  onClick={this.handleButtonClick}
                  style={{
                    paddingRight: "2%",
                    marginLeft: "auto",
                    display: "flex",  
                    cursor: "pointer"               
                  }}
                />
              </Box>
            </Grid>
        </Box>
        <Box className="headerFiled">
          <Grid item xl={12} xs={12} lg={12} sm={12}>
            <Box className="headerWrapper">
              <img
                  alt="backButton"
                  src={arrowIconBack}
                  onClick={this.handleButtonClick}
                  style={{cursor:'pointer'}}
                  />
              <Typography className="headerName">Terms & Conditions</Typography>  
            </Box>
          </Grid>
        </Box>
        <Box style={{background:'#242424'}}>
          <Grid item xl={12} xs={12} lg={12} sm={12}>
            <Box className="contentWrapper">
            {this.renderLoader( <Typography className="condTitle">
              {this.state.termsConstionsList.title}
              </Typography>,{height:"40px"})}
              {this.renderLoader(<Typography className="termsContent" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(this.state.termsConstionsList.description) }}/>,{height:"80px"})}

                <Box style={{marginTop:'40px'}}>  
                                   
                        <Checkbox                        
                          className="checkBox"                         
                          checked={this.state.checked}
                          onClick={this.handleCheckboxClick}                 
                       />
                        <label
                          style={{
                            fontFamily: 'Lato',
                            fontSize: '14px',
                            fontWeight: 400,
                            lineHeight: '22px',
                            textAlign: 'left',
                            color: '#D3D3D3',                       
                          }}
                        >
                          I have read and agree to the terms and conditions
                        </label>
                      </Box>  
                      <Box style={{marginTop:'2px'}}>  
                                   
                                   <Checkbox                        
                                     className="checkBox"                         
                                     checked={this.state.checked2}
                                     onClick={this.handleCheckboxClick2}                 
                                  />
                                   <label
                                     style={{
                                       color: '#D3D3D3',                       
                                       fontFamily: 'Lato',
                                       textAlign: 'left',
                                       fontWeight: 400,
                                       lineHeight: '22px',
                                       fontSize: '14px',
                                     }}
                                     className="DataProtectionBottomBorder"                                   
                                     data-testId="dataProtectionTestID"  
                                   >
                                    I acknowledge the data privacy statement
                                   </label>
                        </Box>  

          <br/>
          {isCompany ? <Button data-testId="BuyCreditToggleTest" className="customBtn" variant="contained" disabled={!this.state.checked || !this.state.checked2} style={
                      !this.state.checked || !this.state.checked2
                        ? { backgroundColor: "#7C7C7C" }
                        : { backgroundColor: "#00FF00" }
                    }
                    onClick={this.handlePaymentDialogBox}
                    >
                      <Typography className="btntypo">
                         {configJSON.comText }
                      </Typography>
          </Button> : <Button className="customBtn" variant="contained" disabled={!this.state.checked || !this.state.checked2 } style={
                      !this.state.checked || !this.state.checked2
                        ? { backgroundColor: "#7C7C7C" }
                        : { backgroundColor: "#00FF00" }
                    }
                    onClick={this.handleButtonClick}
                    >
                      <Typography className="btntypo">
                        { configJSON.empText}
                      </Typography>
          </Button>}
            </Box>
          </Grid>
        </Box>
        <SuccessDailogBoxProfile
         data-testId="confirmDialog_test"
         open={this.state.successDialogOpen}
         onClose={this.handleCancel}
         goToScreen={this.naviagateLogin}   
        />
        <FailDailogBox
         data-testId="confirmDialog_test2"
         open={this.state.failDialogOpen}
         onClose={this.handleCancel}
         handleSuccessDialogBox={this.handleFailureDialogBox}
        />
        <StripePayments
          data-testId="confirmDialog_test3"
          open={this.state.isDialogOpen}
          onClose={this.handleCancel} 
          payAmount={this.state.paymentPrice}
          handleFailDialogBox={this.handleFailDialogBox}
          handleSuccessDialogBox={this.handleSuccessDialogBox}
          payType={this.state.paymentType}
          payPlan={this.state.subscriptionName}
        />
        {this.state.dataProtectionBox && (
          <DataProtectionDiaglog
            open={this.state.dataProtectionBox}
            onClose={this.handleCloseDiaglog}
            disc={this.state.titleDataProtection}
            title={this.state.descriptionDataProtection}
            logo={scaleKYC}
            checked2={this.state.checked2}
            handleCheckboxClick2={this.handleCheckboxClick2}  
          />
         )}
      </TermsConditionsStyles>
    )
        // Customizable Area End
  }
}

// Customizable Area Start
const TermsConditionsStyles = styled(Box)({
  overflowX:"hidden",
  "& .headerFiled":{
    height: '216px',
    top: '80px',
    gap: '10px',
    opacity: '0px',
    background: '#FFFFFF',
  },
  "& .headerName":{
    fontFamily: 'Lato',
    fontSize: '48px',
    fontWeight: 700,
    lineheight: '56px',
    letterSpacing: '-0.015em',
    textAlign: 'left',
    color: '#00FF00'

  },
  "& .headerWrapper":{
    display: "flex",
    alignItems: 'center',
    padding: '80px 112px',
    gap: '20px'
  },
  "& .contentWrapper":{
    padding:"60px 200px 120px 112px",
    top: '296px',
    gap: '40px',
    opacity: '0px'
  },
  "& .condTitle":{
    fontFamily: 'Lato',
    fontWeight: 600,
    lineHeight: '26px',
    textAlign: 'left',
    color:'#D3D3D3',
    paddingBottom: '16px'
  },
  "& .termsContent":{
    fontFamily: 'Lato',
    fontWeight: 500,
    lineHeight: '24px',
    color:'#7C7C7C',
    fontSize:' 16px',
    paddingBottom: '16px',
    textAlign: "justify"

  },
  "& .checkBox":{
  width: "20px",
  height: "20px",
  cursor: "pointer",  
  paddingLeft:"0px",
  marginRight: '8px',
  color: '#D3D3D3',
  '& .MuiSvgIcon-root': {
    fontSize: '20px',
  },
  '&.Mui-checked': {
    color: '#00FF00 !important', // Checked color green
  },
},
'& .DataProtectionBottomBorder: hover':{
   borderBottom: "2px solid blue"
},
'& .customBtn':{
  minWidth: '116px',
  height: '48px',
  marginTop: '16px',
},
'& .btntypo':{
  fontFamily: 'Lato',
  fontWeight: 700,
  fontSize: '16px',
  lineHeight: '24px',
  color: '#242424',
  textTransform: 'none',
  padding:"12px 20.5px"
},
"@media (max-width: 768px)": {
  "& .contentWrapper":{
    padding: "60px 120px 120px 112px"
  }
},
"@media (max-width: 470px)": {
  "& .headerWrapper":{
    padding: "40px 20px"
  },
  "& .contentWrapper":{
    padding: "60px 50px 120px 50px"
  }
},
});
// Customizable Area End
